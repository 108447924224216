import React from "react";
import { Button, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function ProgressBox({ storeData, handleCancelBulkUploadMedia, handleRetryFailMedia, handleErrorDoneButton }) {
  const isUploading = storeData?.isbulkUploading;
  const totalUploads = storeData?.bulkUploadMediaLength || 0;
  const successfulUploads = storeData?.successfulBulkUploadMediaLength || 0;
  const failedUploads = storeData?.failBulkUploadMediaLength || 0;
  const hasFailedMedia = storeData?.failedBulkUploadMedia?.length > 0;

  return (
    <>
      {isUploading && (
        <div className="progress_box">
          <LinearProgress className="linear_progress_bar" />
          <Typography className="upload_progress">Upload in progress. Please wait.</Typography>
          <Button variant="text" className="report_library_cancel_button" onClick={handleCancelBulkUploadMedia}>
            CANCEL
          </Button>
        </div>
      )}

      {totalUploads > 0 && successfulUploads === totalUploads && !isUploading && (
        <div className="progress_box">
          <Typography className="check_circle_icon">
            <CheckCircleOutlineIcon />
          </Typography>
          <Typography className="upload_progress">Upload complete.</Typography>
          <Typography className="upload_progress">
            {totalUploads} of {totalUploads} media have been added to library.
          </Typography>
        </div>
      )}

      {failedUploads > 0 && !isUploading && (
        <div className="progress_box">
          <Typography className="error_icon">
            <ErrorOutlineIcon className="error_icon" />
          </Typography>
          <Typography className="upload_progress">Upload failed.</Typography>
          <Typography className="upload_progress">{failedUploads} file(s) were not uploaded. Make sure the image or media file is</Typography>
          <Typography className="upload_progress">the right format (PNG, JPG, JPEG, MP4, MOV) and try again.</Typography>
          {hasFailedMedia && (
            <Button variant="contained" className="report_library_retry_button" onClick={handleRetryFailMedia}>
              RETRY ALL FAILED IMAGES
            </Button>
          )}
          <Button variant="outlined" className="report_library_error_modal_done_button" onClick={handleErrorDoneButton}>
            DONE
          </Button>
        </div>
      )}
    </>
  );
}

export default ProgressBox;
