import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import uuid from "react-uuid";
import Froalaeditor from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BulbIcon from "../../../Assets/Images/bulbIcon.png";
import AICommentHelper from "../../Components/LineDetails/CommentDetails/AICommentHelper";
import { decodeEntities } from "../../Components/LineDetails/CommentDetails/CommentLibraryHelper";
import EditOptionsDialog from "./EditOptionsDialog";
import "./FroalaEditorStyles.scss";
import CopySummaryModal from "../../Components/LineDetails/CommentDetails/CopySummaryModal";

const FroalaEditorComponent = (props) => {
  const {
    categoryName,
    categoryNameFromLocation,
    showAIModal,
    setShowAIModal,
    setCommentText,
    commentTitle,
    showAI,
    editor,
    id,
    onChange,
    editorData,
    onBlur,
    commentData,
    copy,
    froalaConfig,
    handleDropdownList,
    isEdit,
    dialogRef,
    setEditorContent,
    templateId,
    isFocused,
    setIsFocused,
    setHasChanges,
    setIsButtonAdded
  } = props;

  const [state, setState] = useState({
    open: false,
    openHelp: false,
    dropdownList: id === "intro-text-editor" ? commentData?.introductory_text_dropdowns : id === "commentText" ? commentData?.comment_dropdowns : commentData?.summary_comment_dropdowns,
    editedOption: null,
    dialogOptionList: [],
    selectedDropdown: null,
    isdropdownAdded: true,
    openRemoveDropdown: false
  });
  const { openHelp, dropdownList, selectedDropdown, dialogOptionList, openRemoveDropdown } = state;
  const location = useLocation();
  const storeData = useSelector((state) => state.commentLibrary);
  const templateData = useSelector((state) => state.CommonReducer)?.inspectionData?.data?.inspectiontemplates;
  const templateIndex = templateData?.findIndex((itm) => itm.template_id === templateId);
  const checkNarativeTemplate = () => { if (templateData && templateIndex !== -1) { return templateData[templateIndex]?.is_narrative_template === 1 ? true : false; } }

  const handleOpenDialog = (event, dropdownId) => {
    event?.stopPropagation();
    let newButtonId = event?.target?.id?.replace("dropdownButton", "");
    if (event?.target?.tagName === "BUTTON") {
      let dropdown;
      let dropdowns = dropdownList?.length ? dropdownList : [];
      if (dropdownId) dropdown = dropdowns?.find((obj) => obj?.dropdown_uuid === dropdownId);
      else dropdown = dropdowns?.find((obj) => obj?.dropdown_uuid === newButtonId);
      if (dropdown === undefined) dropdown = dropdowns.find((obj) => obj?.isPasted === true);
      setState((prevState) => ({
        ...prevState,
        dialogOptionList: dropdown?.dropdowns_values.filter((obj) => !obj.toUpperCase().includes("XXX")) || [],
        open: true,
        selectedDropdown: newButtonId || dropdownId || dropdown?.dropdown_uuid,
      }));
    }
  };

  useEffect(() => {
    const editorInstance = editor?.current?.editor;
    let editorContent = editorInstance?.html?.get();
    if (!editorInstance || !editorData?.innerHTML) return;
    let isEffectExecuted = false;
    let newDropdownList = dropdownList?.length ? [...dropdownList] : [];
    let modifiedHtml;
    const replacePlaceholdersWithDropdowns = () => {
      const dropdowns = id === "intro-text-editor" ? commentData?.introductory_text_dropdowns : id === "commentText" ? commentData?.comment_dropdowns : commentData?.summary_comment_dropdowns;
      const regexPlainDropdown = /\[a-f0-9\-]+/g;
      if (!dropdowns?.some((dropdown) => editorData?.innerHTML.includes(dropdown?.dropdown_uuid)) || !dropdowns) {
        return editorData?.innerHTML;
      }
      if (Array?.isArray(dropdowns) && dropdowns?.length > 0) {
        const tempContainer = document.createElement("div");
        tempContainer.innerHTML = commentData?.introductory_text;
        dropdowns.forEach((dropdown) => {
          let optionValues = [];
          const spanElements = Array.from(tempContainer.querySelectorAll(`span[contenteditable="false"][id="[dropdown ${dropdown?.dropdown_uuid}]"]`));
          if (spanElements?.length > 0) {
            spanElements.forEach((spanElement) => {
              spanElement.innerHTML = "";
              const selectElement = document.createElement("select");
              let newDropdownValues = Array.isArray(dropdown?.dropdowns_values?.values) ? dropdown?.dropdowns_values?.values : dropdown?.dropdowns_values;
              if (Array.isArray(newDropdownValues)) {
                newDropdownValues = newDropdownValues.filter((obj) => !obj.toUpperCase().includes("XXX"));
                if (!newDropdownValues.find((value) => value.includes("------"))) {
                  newDropdownValues.unshift("-------------");
                }
                newDropdownValues.forEach((value) => {
                  const option = document.createElement("option");
                  option.text = value?.length > 40 ? `${value.substring(0, 40)}...` : value || "";
                  option.value = value || "";
                  option.title = value || "";
                  if (dropdown?.selected_value === value) {
                    option.setAttribute("selected", value);
                  }
                  selectElement.appendChild(option);
                });
              }
              selectElement.classList.add(`${id === "intro-text-editor" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown`);
              selectElement.setAttribute("id", dropdown.dropdown_uuid);
              if (id !== "intro-text-editor") {
                selectElement.setAttribute("comment_id", commentData?.comment_id);
                selectElement.setAttribute("comment_uuid", commentData?.comment_uuid);
                selectElement.setAttribute("dropType", id === "commentText" ? "comment" : "summary");
                selectElement.addEventListener("change", handleSelectChange);
              }
              optionValues = Array.from(selectElement.options)
                .map((option) => option.value)
                .filter((value) => value !== "-------------");
              newDropdownList.push({
                dropdown_uuid: dropdown.dropdown_uuid,
                name: `dropdown ${dropdown.dropdown_uuid}`,
                selected_value: dropdown.selected_value,
                dropdowns_values: optionValues || [],
                isPasted: true,
              });
              spanElement.appendChild(selectElement);
              const buttonElement = document.createElement("button");
              buttonElement.setAttribute("id", `dropdownButton${dropdown.dropdown_uuid}`);
              buttonElement.setAttribute("contenteditable", "false");
              buttonElement.setAttribute("name", `${dropdown.dropdown_uuid}`);
              buttonElement.classList.add("dropdown-button");
              buttonElement.setAttribute("type", "button");
              buttonElement.addEventListener("click", (event) => {
                handleOpenDialog(event, dropdown.dropdown_uuid);
              });
              buttonElement.style.cssText = `background-color: #f1f9fe;
                  width: 24px;
                  height: 24px;
                  padding: 0px;
                  color: #4282e2;
                  border-radius: 4px 0px 0px 4px;
                  border: 1px solid #4282e2;
                  position: relative;
                  transform: scaleX(-1);
                  font-size: 17px;
                  margin-left: -4px;
                  top: 2px;`;
              buttonElement.textContent = "✎";
              spanElement.appendChild(buttonElement);
              const styleTag = document.createElement("style");
              styleTag.innerHTML = `
                @media screen and (max-width: 1100px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 25.5px;
                  }
                }
                @media screen and (min-width: 1600px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 2.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 1700px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 2.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 1800px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 2.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 1900px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 2px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 2000px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 1.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
                @media screen and (min-width: 2100px) {
                  #dropdownButton${dropdown.dropdown_uuid} {
                    top: 1.5px;
                    width: initial;
                    margin-left: -5px;
                  }
                }
              `;
              document.head.appendChild(styleTag);
            });
          } else {
            var placeholderRegex = /\[dropdown\s([a-f0-9-]+)\]/g;
            modifiedHtml = commentData?.introductory_text?.replace(placeholderRegex, (match, dropdownId) => {
              const dropdown = dropdownList?.find((item) => item?.dropdown_uuid === dropdownId);
              if (dropdown) {
                let optionsHtml = dropdown?.dropdowns_values?.length === 0
                  ? ""
                  : dropdown?.dropdowns_values?.filter((obj) => !obj?.toUpperCase().includes("XXX"))
                    ?.map((value) => {
                      const isSelected = value?.trim() === dropdown?.selected_value?.trim();
                      return `<option title="${value}" value="${value}" ${isSelected ? " selected" : ""}>${value}</option>`;
                    })
                    .join("");
                return `<span contenteditable="false" style="white-space: nowrap;" id="[dropdown ${dropdownId}]">
                  <select id="${dropdownId}" style="width: 104px;
                          background-color: #f1f9fe;
                          height: 24px;
                          overflow-wrap: break-word;
                          padding: 1px 17px 3px 6px;
                          background-position: right 4px center;
                          background-repeat: no-repeat;
                          background-size: 22px 20px;
                          border-radius: 4px 0px 0px 4px;
                          border: 1px solid #4282e2;
                          appearance: none;
                          -webkit-appearance: none;
                          -moz-appearance: none;
                          font-size: 14px !important;
                          color: #3B3B3B !important;
                          font-style: normal !important;
                          font-weight: 600 !important;
                          max-width: 200px !important;
                          min-width: 104px !important;
                          text-overflow: ellipsis !important;
                          white-space: nowrap !important;
                          width: -webkit-max-content !important;
                          width: max-content !important;
                          background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7%2010L12%2015L17%2010H7Z%22%20fill%3D%22%23777777%22/%3E%3C/svg%3E');
                          onchange="handleSelectChange(event, '${dropdownId}')"
                          ">
                    <option value="-------------">-------------</option>
                    ${optionsHtml}
                  </select>
                  <button id="dropdownButton${dropdownId}" contenteditable="false" name="${dropdownId}" type="button" style="background-color: #f1f9fe;
                          width: 24px;
                          height: 24px;
                          padding: 0px;
                          color: #4282e2;
                          border-radius: 4px 0px 0px 4px;
                          border: 1px solid #4282e2;
                          position: relative;
                          transform: scaleX(-1);
                          font-size: 17px;
                          margin-left: -4px;
                          display: inline-flex;
                          vertical-align: middle;
                          top: -1px;">
                    ✎
                  </button>
                </span>`;
              }
              return match;
            });
          }
        });
        editorData.innerHTML = tempContainer.innerHTML;
      } else {
        const outputString = editorData?.innerHTML?.replace(regexPlainDropdown, (match, selectId) => {
          return `<select id="${selectId}" class="${id === "intro-text-editor" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown"><option>--------------</option></select>`;
        });
        editorData.innerHTML = outputString;
      }
      return decodeEntities(copy ? editorData.innerHTML : editorData.innerHTML?.replace(regexPlainDropdown, ""));
    };
    const isIntroTextEmpty =
      commentData?.introductory_text === "<p></p>" || commentData?.introductory_text === "";
    const isEditorEmpty = !editorData?.innerHTML || editorData?.innerHTML === "<p></p>";
    setTimeout(async () => {
      if (id !== "intro-text-editor") {
        if (isIntroTextEmpty && isEditorEmpty) {
          editorInstance?.html?.set("<p></p>");
        }
        else if (editorData?.innerHTML && commentData?.introductory_text) {
          if (!isEffectExecuted || (!editorContent || editorContent === undefined || copy) && editorInstance) {
            isEffectExecuted = true;
            const newHtmlData = replacePlaceholdersWithDropdowns();
            if (!isFocused) {
              editorInstance.html.set(commentData?.comment_dropdowns?.length || commentData?.summary_comment_dropdowns?.length || commentData?.introductory_text_dropdowns?.length ? modifiedHtml || newHtmlData || "" : editorData?.innerHTML || "");
            }
          }
        }
        else {
          editorInstance?.html?.set("<p></p>");
        }
      }
    }, 100);
    return () => {
      isEffectExecuted = false;
    };
  }, [commentData, id, editorData, copy]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, dropdownList: id === "intro-text-editor" ? commentData?.introductory_text_dropdowns : id === "commentText" ? commentData?.comment_dropdowns : commentData?.summary_comment_dropdowns || [] }));
  }, [commentData, id]);

  const handleModelChange = (model) => {
    let htmlString = model;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const pastedElements = doc?.querySelectorAll(`[id^="isPasted"], [id^="pasted/"], #isPasted`);
    const spanElements = doc?.querySelectorAll("span:not([id]):has(select)");
    let checkPaste = false;
    if (pastedElements.length > 0) {
      pastedElements.forEach((element) => {
        if (element?.tagName === "SPAN" || element?.tagName === "SELECT") {
          if (element?.tagName === "SPAN") {
            const selectElement = element?.querySelector("select");
            if (selectElement) {
              checkPaste = true;
              element.remove();
            }
          } else if (element?.tagName === "SELECT") {
            checkPaste = true;
            element.remove();
          }
        } else if (element?.tagName === "BR" || element?.tagName === "P") {
          function findNextSpan(node) {
            while (node) {
              if (node?.tagName === "SPAN" || node?.tagName === "SELECT") return node;
              if (node?.tagName === "P") {
                const nestedSpan = node?.querySelector("span");
                if (nestedSpan) return nestedSpan;
                const nestedSelect = node?.querySelector("select");
                if (nestedSelect) return nestedSelect;
              }
              node = node.nextElementSibling;
            }
            return null;
          }
          const targetSpan = findNextSpan(element.nextElementSibling);
          if (targetSpan?.tagName === "SPAN") {
            const selectElement = targetSpan?.querySelector("select");
            if (selectElement) {
              checkPaste = true;
              selectElement.remove();
            }
          } else if (targetSpan?.tagName === "SELECT") {
            checkPaste = true;
            targetSpan.remove();
          }
        }
      });
      if (spanElements.length > 0) {
        spanElements.forEach((element) => {
          const selectElement = element?.querySelector("select");
          if (selectElement) {
            checkPaste = true;
            selectElement.remove();
          }
          const buttonElement = element?.querySelector("button");
          if (buttonElement) {
            checkPaste = true;
            buttonElement.remove();
          }
        });
      }
    }
    if (checkPaste) {
      setState((prevState) => ({ ...prevState, openRemoveDropdown: doc.body.innerHTML }));
    } else {
      setTimeout(() => {
        const updatedContent = editor.current?.editor?.html.get();
        onChange(id, updatedContent);
      }, 0);
    }
    if (setIsFocused) setIsFocused(true);
  };

  let isHandlingImage = false;

  // const insertImageToEditor = (base64String) => {
  //   const editorInstance = editor.current.editor;
  //   const cursorPosition = editorInstance.selection.get();
  //   if (cursorPosition) editorInstance?.image?.insert(editorData, true);
  //   isHandlingImage = false;
  // };

  const handleImageBeforeUpload = (images) => {
    // if (images.length && !isHandlingImage) {
    //   isHandlingImage = true;
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     insertImageToEditor(e.target.result);
    //   };
    //   reader.readAsDataURL(images[0]);
    // }
    return false;
  };

  const handlePasteBefore = (e) => {
    if (isHandlingImage) return;
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        e.preventDefault();
        // isHandlingImage = true;
        // const blob = items[i].getAsFile();
        // if (!blob) {
        //   const reader = new FileReader();
        //   reader.onload = (event) => {
        //     insertImageToEditor(event.target.result);
        //   };
        // reader.readAsDataURL(blob);
        // }
      } else if (items[i].type.indexOf("text/html") !== -1) {
        items[i].getAsString((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          // const images = doc.querySelectorAll("img");
          // images.forEach((img) => {
          //   const src = img.src;
          //   if (src.startsWith("data:image")) {
          // insertImageToEditor(src);
          //   }
          // });
          e.preventDefault();
          const images = doc.querySelectorAll("img");
          images.forEach((img) => {
            const src = img.src;
            if (src.startsWith("data:image")) {
              img.parentNode.removeChild(img);
            }
          });
          doc.querySelectorAll("img, figure, a").forEach((el) => el.remove());
          const elementsWithBgImages = doc.querySelectorAll("[style]");
          elementsWithBgImages.forEach((el) => {
            const style = el.getAttribute("style");
            if (style && style.includes("url(")) {
              el.removeAttribute("style");
            }
          });
          const editorInstance = editor.current.editor;
          if (editorInstance) {
            setTimeout(() => {
              editorInstance.html.insert(doc.body.innerHTML, true);
            }, 100);
          }
        });
      }
    }
  };

  const handleSelectChange = (event) => {
    const newValue = event?.target?.value?.length > 40 ? `${event?.target?.value.substring(0, 40)}...` : event?.target?.value;
    let newDropdownList = id === "intro-text-editor" ? commentData?.introductory_text_dropdowns : id === "commentText" ? commentData?.comment_dropdowns : commentData?.summary_comment_dropdowns;
    if (!isEdit) {
      let updatedDropdownList;
      updatedDropdownList = newDropdownList?.map((dropdown) => {
        if (dropdown?.dropdown_uuid === event.target.id) {
          return { ...dropdown, selected_value: newValue || "" };
        }
        return dropdown;
      });
      setState((prevState) => ({ ...prevState, dropdownList: updatedDropdownList }));
      handleDropdownList?.(id, updatedDropdownList,);
    } else {
      if (id === "intro-text-editor") newDropdownList = dropdownList;
      const index = newDropdownList.findIndex((obj) => obj?.dropdown_uuid === event.target.id);
      newDropdownList[index] = { ...newDropdownList[index], selected_value: newValue || "" };
      setState((prevState) => ({ ...prevState, dropdownList: newDropdownList }));
      handleDropdownList?.(id, newDropdownList,);
    }
    const selectRegex = new RegExp(`<select[^>]*?id=["']${event.target.id}["'][\\s\\S]*?<\\/select>`, "g");
    const editorInstance = editor.current?.editor;
    let editorContent = editorInstance?.html?.get();
    editorContent = editorContent.replace(selectRegex, (selectMatch) => {
      const optionRegex = /<option([^>]*)>([^<]+)<\/option>/g;
      return selectMatch.replace(optionRegex, (optionMatch, attributes, value) => {
        const isSelected = value.trim() === newValue.trim();
        const updatedAttributes = attributes.replace(/selected/g, "").trim();
        return `<option ${updatedAttributes} ${isSelected ? "selected" : ""}>${value}</option>`;
      });
    });
    editorInstance.html.set(editorContent);
    setEditorContent({ innerHTML: editorContent });
  };

  useEffect(() => {
    const editorInstance = editor.current?.editor;
    if (editorInstance) {
      const contentArea = editorInstance.$el.get(0);
      contentArea.addEventListener("click", handleOpenDialog);
      contentArea.addEventListener("change", handleSelectChange);
      return () => {
        contentArea.removeEventListener("click", handleOpenDialog);
        contentArea.removeEventListener("change", handleSelectChange);
      };
    }
  }, [editorData, commentData]);

  useEffect(() => {
    document.addEventListener("openDialog", handleOpenDialog);
    document.addEventListener("changeValue", handleSelectChange);
    return () => {
      document.removeEventListener("openDialog", handleOpenDialog);
      document.removeEventListener("changeValue", handleSelectChange);
    };
  }, [commentData]);

  const btnclick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let newDropdownList = dropdownList?.length ? [...dropdownList] : [];
    const editorInstance = editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    const newSelectUuid = uuid();
    if (cursorPosition) {
      editorInstance.html.insert(
        `<span contenteditable="false" style="white-space: nowrap;" id="[dropdown ${newSelectUuid}]">
            <select id="${newSelectUuid}"
                style="width: 104px;
                background-color: #f1f9fe;
                height: 24px;
                overflow-wrap: break-word;
                padding: 1px 17px 3px 6px;
                background-position: right 4px center;
                background-repeat: no-repeat;
                background-size: 22px 20px;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #4282e2;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                font-size: 14px !important;
                color: #3B3B3B !important;
                font-style: normal !important;
                font-weight: 600 !important;
                max-width: 200px !important;
                min-width: 104px !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                width: -webkit-max-content !important;
                width: max-content !important;
                background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7%2010L12%2015L17%2010H7Z%22%20fill%3D%22%23777777%22/%3E%3C/svg%3E');
                onchange="handleSelectChange(event, '${newSelectUuid}')"
                " >
                <option selected value="-------------">-------------</option>
            </select>
            <style>
              #dropdownButton${newSelectUuid} {
                background-color: #f1f9fe;
                width: 24px;
                height: 24px;
                padding: 0px;
                color: #4282e2;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #4282e2;
                position: relative;
                transform: scaleX(-1);
                font-size: 17px;
                margin-left: -4px;
                display: inline-flex;
                vertical-align: middle;
                top: -1px;
              }
              @media screen and (max-width: 1100px) {
                #dropdownButton${newSelectUuid} {
                  top: 25.5px;
                }
              }
              @media screen and (min-width: 1600px) {
                #dropdownButton${newSelectUuid} {
                  top: 2.5px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 1700px) {
                #dropdownButton${newSelectUuid} {
                  top: 2.5px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 1800px) {
                #dropdownButton${newSelectUuid} {
                  top: 2.5px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 1900px) {
                #dropdownButton${newSelectUuid} {
                  top: 2px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 2000px) {
                #dropdownButton${newSelectUuid} {
                  top: -1.5px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 2100px) {
                #dropdownButton${newSelectUuid} {
                  top: 50px;
                  width: initial;
                  margin-left: -5px;
                }
              }
              @media screen and (min-width: 2300px) {
                #dropdownButton${newSelectUuid} {
                  top: 50px;
                  width: initial;
                  margin-left: -5px;
                }
              }
               @media screen and (min-width: 2500px) {
                #dropdownButton${newSelectUuid} {
                  top: -2px !important;
                  width: initial;
                  margin-left: -5px;
                }
              }
            </style>
            <button
              id="dropdownButton${newSelectUuid}"
              contenteditable="false"
              name="${newSelectUuid}"
              classname="dropdown-button"
              type="button"
              onclick="handleOpenDialog(event, '${newSelectUuid}');"
              style=" background-color: #f1f9fe;
                width: 24px;
                height: 24px;
                padding: 0px;
                color: #4282e2;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #4282e2;
                position: relative;
                transform: scaleX(-1);
                font-size: 17px;
                margin-left: -4px;
                display: inline-flex;
                vertical-align: middle;
                top: -1px; "
            >
              ✎
            </button>
        </span>
    `,
        true
      );
    }
    newDropdownList.push({
      dropdown_uuid: newSelectUuid,
      name: `dropdown ${newSelectUuid}`,
      selected_value: "",
      dropdowns_values: [],
    });
    setState((prevState) => ({ ...prevState, dropdownList: newDropdownList, }));
    if (setIsFocused) setIsFocused(true);
    if (setHasChanges) setHasChanges(true);
    if (setIsButtonAdded) setIsButtonAdded(true)
    handleDropdownList?.(id, newDropdownList);
    let editorContent = editorInstance.html.get();
    setEditorContent({ innerHTML: editorContent || "" });
    setTimeout(() => {
      const button = editor.current.editor.$el.find(`#dropdownButton${newSelectUuid}`);
      const select = editor.current.editor.$el.find(`#${newSelectUuid}`);
      button.on("click", function (event) {
        const customEvent = new CustomEvent("openDialog", { detail: { id: newSelectUuid, event: event } });
        document.dispatchEvent(customEvent);
      });
      select.on("change", function (event) {
        const customEvent = new CustomEvent("changeValue", { detail: { id: newSelectUuid, event: event } });
        document.dispatchEvent(customEvent);
      });
    }, 0);
  };

  const handleSave = () => {
    if (setHasChanges) setHasChanges(true);
    let index = dropdownList.findIndex((obj) => obj?.dropdown_uuid === selectedDropdown);
    let newDropdownOptions = dropdownList?.length ? [...dropdownList] : [];
    if (index === -1) index = dropdownList.findIndex((obj) => obj?.isPasted === true);
    const editorInstance = editor.current.editor;
    let editorContent = editorInstance.html.get();
    const optionElements = dialogOptionList.map((option) => {
      const truncatedOption = option.length > 40 ? option.substring(0, 40) + "..." : option;
      const isSelected = dropdownList[index]?.selected_value === option;
      return `<option title="${option}" value="${option}" ${isSelected ? "selected" : ""}>${truncatedOption}</option>`;
    });
    const optionsString = optionElements.join("");
    const selectRegex = new RegExp(`<select[^>]*?id=["']${selectedDropdown}["'][\\s\\S]*?<\\/select>.*?`, "g");
    function replaceOptionsInSelect(selectHtml, newOptionsHtml) {
      const optionsRegex = /(<select[^>]*>)[\s\S]*?(<\/select>)/;
      return selectHtml.replace(optionsRegex, `$1${newOptionsHtml}$2`);
    }
    const newOptionsHtml = `<option value="-------------">-------------</option>${optionsString}`;
    const replacedContent = editorContent.replace(selectRegex, (match) => replaceOptionsInSelect(match, newOptionsHtml));
    editorInstance.html.set(replacedContent);
    const newDialogOptionList = dialogOptionList.filter((option) => option.trim() !== "");
    if (newDropdownOptions.length === 0) {
      newDropdownOptions.push({ dropdown_uuid: selectedDropdown, selected_value: "", dropdowns_values: newDialogOptionList?.length === 0 ? ["------------"] : newDialogOptionList });
    } else {
      newDropdownOptions[index] = {
        ...newDropdownOptions[index],
        dropdowns_values: newDialogOptionList || [],
      };
    }
    handleDropdownList?.(id, newDropdownOptions);
    setEditorContent({ innerHTML: editorContent });
    setState((prevState) => ({ ...prevState, open: false, dialogOptionList: [], dropdownList: newDropdownOptions }));
  };

  const openHelpDialog = () => {
    setState((prevState) => ({ ...prevState, openHelp: !openHelp }));
    setShowAIModal(!showAIModal);
  };

  Froalaeditor.DefineIcon("aiHelper", { SRC: BulbIcon, ALT: "Image button", height: 50, width: "50px", template: "image" });
  Froalaeditor.RegisterCommand("aiHelper", {
    title: "AI helper",
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      openHelpDialog();
    },
  });
  // const handleFocus = () => {
  //   setState((prevState) => ({ ...prevState, isFocused: true }));
  // };

  // const handleBlur = () => {
  //   setState((prevState) => ({ ...prevState, isFocused: false }));
  //   const editorInstance = editor.current?.editor;
  //   const editorContent = editorInstance?.html?.get();
  //   if (onBlur) {
  //     setTimeout(() => {
  //       onBlur(id, editorContent);
  //     }, 100);
  //   }
  // };

  const toolbarButtonsConfig = {
    moreText: { buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"], align: "left", buttonsVisible: 3 },
    moreRich: { buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"], align: "left", buttonsVisible: 0 },
    moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
  };

  if (showAI) {
    toolbarButtonsConfig.aiHelper = { buttons: ["aiHelper"], align: "left" };
  }

  const handleSetComment = (values) => {
    const editorInstance = editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    if (cursorPosition) {
      editorInstance.html.insert(`<div><div>${values}</div></div>`);
    }
  };

  const handlePasteAfter = () => {
    const parser = new DOMParser();
    const editorInstance = editor?.current?.editor;
    const editorData = editorInstance?.html?.get();
    const document = parser.parseFromString(editorData, "text/html");
    const selects = document.querySelectorAll("select")
    if (selects?.length > 0) {
      setState((prevState) => ({ ...prevState, openRemoveDropdown: editorData }))
    }
  }

  const setEditorData = (data, type) => {
    const editorInstance = editor?.current?.editor;
    const parser = new DOMParser();
    const document = parser.parseFromString(editorInstance?.html?.get(), "text/html");
    document.querySelectorAll("span").forEach((span) => {
      const hasSelect = span.querySelector("select");
      const hasButton = span.querySelector("button");
      const otherContent = span.cloneNode(true);
      otherContent.querySelectorAll("select, button").forEach((child) => child.remove());
      if (otherContent.innerHTML.trim()) {
        const pTag = document.createElement("p");
        pTag.innerHTML = otherContent.innerHTML;
        span.parentNode.insertBefore(pTag, span.nextSibling);
      }
      if (!hasSelect?.id || !hasButton?.id) {
        hasSelect?.remove();
        hasButton?.remove();
        span.remove();
      }
    });
    document.querySelectorAll("select:not([id]), select[id='isPasted']").forEach((select) => {
      const nextSibling = select.nextElementSibling;
      if (!select?.id) select.remove();
      if (nextSibling && nextSibling?.tagName.toLowerCase() === 'button') {
        nextSibling.remove();
      }
    });
    document.querySelectorAll("p, div, span").forEach((el) => {
      if (!el.textContent.trim()) {
        el.remove();
      }
    });
    const pastedElements = document?.querySelectorAll(`[id^="isPasted"], [id^="pasted/"], #isPasted`);
    if (type === "cancel") {
      if (pastedElements?.length > 0) {
        const pastedDoc = parser.parseFromString(data, "text/html");
        pastedElements.forEach((element) => {
          if (element?.tagName === "BR" || element?.tagName === "P") {
            function findNextSpan(node) {
              while (node) {
                if (node?.tagName === "SPAN" || node?.tagName === "SELECT") return node;
                if (node?.tagName === "P") {
                  const nestedSpan = node?.querySelector("span");
                  if (nestedSpan) return nestedSpan;
                  const nestedSelect = node?.querySelector("select");
                  if (nestedSelect) return nestedSelect;
                }
                node = node.nextElementSibling;
              }
              return null;
            }
            const targetSpan = findNextSpan(element.nextElementSibling);
            if (targetSpan?.tagName === "SPAN") {
              const selectElement = targetSpan?.querySelector("select");
              if (selectElement) {
                selectElement.remove();
              }
            } else if (targetSpan?.tagName === "SELECT") {
              targetSpan.remove();
            }
          }
        })
        pastedDoc.querySelectorAll("select:not([id])").forEach((select) => {
          const nextSibling = select.nextElementSibling;
          if (!select?.id) select.remove();
          if (nextSibling && nextSibling?.tagName.toLowerCase() === 'button') {
            nextSibling.remove();
          }
        });
        pastedDoc.querySelectorAll("button:not([id])").forEach((button) => {
          if (!button?.id) button.remove();
        });
        onChange(id, pastedDoc.body.innerHTML);
        editorInstance?.html?.set(pastedDoc.body.innerHTML);
      } else {
        onChange(id, document.body.innerHTML);
        editorInstance?.html?.set(document.body.innerHTML);
      }
    } else {
      if (pastedElements?.length > 0) {
        pastedElements.forEach((element) => {
          const textContent = element.textContent.trim();
          if (textContent) {
            const textNode = document.createTextNode(textContent);
            element.parentNode.replaceChild(textNode, element);
          } else {
            element.remove();
          }
        });
      }
      onChange(id, document.body.innerHTML);
      editorInstance?.html?.set(document.body.innerHTML);
    }
  };

  const handleCloseRemoveDropdown = () => {
    setEditorData(editorData?.innerHTML, "cancel");
    setState((prevState) => ({ ...prevState, openRemoveDropdown: false }));
  };

  const handleSubmitRemoveDropdown = () => {
    setEditorData(null, "paste");
    setState((prevState) => ({ ...prevState, openRemoveDropdown: false }));
  };

  return (
    <div id={id}>
      <FroalaEditor
        id={id}
        ref={editor}
        value={editorData}
        onModelChange={handleModelChange}
        config={{
          key: "1CC3kA6C7A5A3B3A3E3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C7A4A4A4G3H3B1==",
          spellCheck: true,
          imagePaste: false,
          placeholderText: " ",
          charCounterCount: false,
          quickInsertEnabled: false,
          toolbarButtons: toolbarButtonsConfig,
          pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
          events: {
            "paste.after": handlePasteAfter,
            // "image.beforeUpload": handleImageBeforeUpload,
            // "paste.before": handlePasteBefore,
            // focus: handleFocus,
            // blur: handleBlur,
          },
          ...froalaConfig,
        }}
      />
      <AICommentHelper
        categoryName={categoryName}
        categoryNameFromLocation={categoryNameFromLocation}
        showAIModal={showAIModal}
        setShowAIModal={setShowAIModal}
        id={id}
        editor={editor}
        setCommentText={setCommentText}
        commentTitle={commentTitle}
        open={openHelp}
        onClose={openHelpDialog}
        handleSetComment={handleSetComment}
      />
      {id === "intro-text-editor" && checkNarativeTemplate() ? (
        <Grid display={"flex"} className="add-dropdown-box">
          <Button className="add-dropdown-typo" id="add-dropdown" onClick={(event) => btnclick(event)} disableRipple startIcon={<AddCircleOutlineOutlinedIcon className="add-dropdown-circle" />}>
            Add dropdown
          </Button>
        </Grid>
      ) : null}
      <EditOptionsDialog dialogRef={dialogRef} id={id} state={state} setState={setState} handleSave={handleSave} />
      {openRemoveDropdown && <CopySummaryModal
        dialogRef={dialogRef}
        open={openRemoveDropdown}
        handleClose={handleCloseRemoveDropdown}
        handleSubmit={handleSubmitRemoveDropdown}
        submitButtonTitle={"Paste Without Dropdown"}
        dialogTitle={"Dropdown Won't Be Included"}
      />}
    </div>
  );
};

export default FroalaEditorComponent;