import React, { useState } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Button, Grid, LinearProgress, Typography, Box } from "@mui/material";
import ImageCarousel from "../../Components/LineDetails/ImageCarousel";
import ImageViewer from "../../Common/CollapseHeader/ImageViewer";
import noImage from "../../../Assets/Images/no-photo.png";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ProgressBox from "./ProgressBox";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteMediaModal from "./DeleteMediaModal";

function ReportLibraryAllMedia({
  storeData,
  handleCancelBulkUploadMedia,
  mediaList,
  handleErrorDoneButton,
  handleRetryFailMedia,
  setHoveredDeletedMediaUuid,
  hoveredDeletedMediaUuid,
  openDeleteMediaModal,
  setOpenDeleteMediaModal,
  handleClose,
  setDeleteMedia,
  deleteMedia,
  handleDeleteRMLMedia,
  view,
}) {
  const [mediaPlayer, setMediaPlayer] = useState(false);
  const [imageviewer, setImageviewer] = useState(false);
  const [link, setLink] = useState("");
  const [imagelink, setImageLink] = useState("");
  const [videoId, SetVideoId] = useState("");
  const [mediaName, setMediaName] = useState("");
  const [mediaLineName, setMediaLineName] = useState("");
  const [imageLineName, setImageLineName] = useState("");
  const [imageCategoryName, setImageCategoryName] = useState("");

  const imgPreview = (imgUrl, categoryName, lineName) => {
    setImageLink(imgUrl);
    setImageLineName(lineName);
    setImageCategoryName(categoryName);
    setImageviewer(true);
  };

  const pdfImgPreview = (imgUrl, categoryName, ElmName) => {
    setImageLink(imgUrl);
    setImageLineName("");
    setImageviewer(true);
  };
  return (
    <>
      <Box
        sx={{
          height: `${
            storeData?.isbulkUploading
              ? "calc(100vh - 288px)"
              : storeData?.failBulkUploadMediaLength > 0 && !storeData?.isbulkUploading
              ? "calc(100vh - 374px)"
              : storeData?.bulkUploadMediaLength > 0 &&
                storeData?.successfulBulkUploadMediaLength > 0 &&
                storeData?.bulkUploadMediaLength === storeData?.successfulBulkUploadMediaLength &&
                !storeData?.isbulkUploading
              ? "calc(100vh - 262px)"
              : "calc(100vh - 141px)"
          }`,
          overflow: "scroll",
        }}
      >
        <Grid container sx={{ display: "flex", padding: "6px 0px 0px 14px" }}>
          {mediaList?.length != 0 ? (
            mediaList?.map((media, index) => {
              let thumbnailUrl = `${storeData.inspectionData?.data?.bucket_url}${
                media?.thumbnail == null || media?.thumbnail_url == null ? media?.onlineUrl || media?.online_url : media?.thumbnail || media?.thumbnail_url
              }`;
              let onlineUrl = `${storeData.inspectionData?.data?.bucket_url}${media?.onlineUrl || media?.online_url}`;
              return media?.online === "offline" ? (
                ""
              ) : (
                <Grid item xs={12} sm={2.4} sx={{ position: "relative" }}>
                  {media?.onlineUrl == null && media?.online_url == null ? (
                    <div style={{ position: "relative" }}>
                      <img src={noImage} className="report_media" style={{ cursor: "pointer", objectFit: "scale-down", backgroundColor: "#FAFAFA" }} />

                      {media?.is_media_uploading == false ? (
                        <WarningAmberIcon className="bulk_upload_circular_bar" sx={{ color: "#E02020", width: "19.17px", height: "16.67px" }} />
                      ) : (
                        <CircularProgress className="bulk_upload_circular_bar" />
                      )}
                    </div>
                  ) : media?.type?.startsWith("image/") || media?.media_type?.startsWith("image/") ? (
                    <div
                      onMouseEnter={() => {
                        setHoveredDeletedMediaUuid(media?.id || media?.uuid);
                      }}
                      onMouseLeave={() => {
                        setHoveredDeletedMediaUuid("");
                      }}
                    >
                      <img
                        className= "report_media" 
                        style={{ cursor: "pointer", backgroundColor: "#FAFAFA" }}
                        src={thumbnailUrl}
                        onClick={() => imgPreview(onlineUrl)}
                        alt="report_media"
                        key={index}
                      />
                      {hoveredDeletedMediaUuid == (media?.id || media?.uuid) && (
                        <RiDeleteBin6Line
                          className="report_media_delete_icon"
                          onClick={() => {
                            setOpenDeleteMediaModal(true);
                            setDeleteMedia(media);
                          }}
                        />
                      )}
                    </div>
                  ) : media?.type?.startsWith("video/") || media?.media_type?.startsWith("video/") ? (
                    <div
                      className="report_media_thumb_wrapper_review report_video_thumb_container"
                      key={index}
                      onMouseEnter={() => {
                        setHoveredDeletedMediaUuid(media?.id || media?.uuid);
                      }}
                      onMouseLeave={() => {
                        setHoveredDeletedMediaUuid("");
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          className="report_media" 
                          style={{ cursor: "pointer", backgroundColor: "#FAFAFA" }}
                          src={thumbnailUrl}
                          alt="video"
                          onClick={() => {
                            setMediaPlayer(true);
                            setLink(onlineUrl);
                          }}
                        />
                        <PlayCircleIcon
                          className="report_media_play_icon"
                          onClick={() => {
                            setMediaPlayer(true);
                            setLink(onlineUrl);
                          }}
                        />
                      </div>
                      {hoveredDeletedMediaUuid == (media?.id || media?.uuid) && (
                        <RiDeleteBin6Line
                          className="report_media_delete_icon"
                          onClick={() => {
                            setOpenDeleteMediaModal(true);
                            setDeleteMedia(media);
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <img src={noImage} className="report_media" style={{ cursor: "pointer", objectFit: "scale-down", backgroundColor: "#FAFAFA" }} />
                      <WarningAmberIcon className="bulk_upload_circular_bar" sx={{ color: "#E02020", width: "19.17px", height: "16.67px" }} />
                    </div>
                  )}
                  {mediaPlayer && <ImageCarousel videolink={link} videoEditor={mediaPlayer} setVideoEditor={setMediaPlayer} videoId={videoId} mediaName={mediaName} mediaLineName={mediaLineName} />}
                  {imageviewer && <ImageViewer imageviewer={imageviewer} setImageviewer={setImageviewer} imageLink={imagelink} imageLineName={imageLineName} imageCategoryName={imageCategoryName} />}
                </Grid>
              );
            })
          ) : (
            <div className="no_media_found">
              <DoDisturbAltIcon sx={{ color: "#979797" }} />
              <Typography sx={{ marginLeft: "-80px" }}>No images or videos are in the</Typography>
              <Typography sx={{ marginLeft: "-26px" }}>report library.</Typography>
            </div>
          )}
        </Grid>
      </Box>
      <ProgressBox storeData={storeData} handleCancelBulkUploadMedia={handleCancelBulkUploadMedia} handleRetryFailMedia={handleRetryFailMedia} handleErrorDoneButton={handleErrorDoneButton} />
      <DeleteMediaModal
        open={openDeleteMediaModal}
        handleClose={handleClose}
        deleteMedia={deleteMedia}
        cancelBtnAction={handleClose}
        submitBtnAction={handleClose}
        handleDeleteRMLMedia={handleDeleteRMLMedia}
      />
    </>
  );
}

export default ReportLibraryAllMedia;
