import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Badge from "@mui/material/Badge";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import ViewHeadlineOutlinedIcon from "@mui/icons-material/ViewHeadlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../../Assets/Styles/Containers/_header.scss";
import { ListItemIcon, ListItemText } from "@mui/material";
import { editCategory, editLine, getTemplateListCall, reorderCategory, reorderLines, swapInspectionDetailsCall } from "../../Redux/Actions/CommonActions";
import AddTemplateModal from "./AddTemplateModal";
import { getDeletedTemplateData } from "../../Helpers/ApiCall";
import { TbPrinterOff } from "react-icons/tb";
import toast from "react-hot-toast";

const Header = ({ title, action, categoryData, pdfCategoryData, stickyHeader, assosiatedCategoryColors }) => {
  const [addTemplateModal, setAddTemplateModal] = useState(false);
  const [deleteTemplateModal, setDeteleteTemplateModal] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  let templateId = location?.state?.template_id;
  const navigate = useNavigate();
  const path = location.pathname.split("/");
  const open = Boolean(anchorEl);
  const storeData = useSelector((state) => state.CommonReducer);
  const inspectionId = storeData?.inspectionData?.data?.inspection_id;
  let templateData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId);
  const IsPdf = templateData?.is_pdf_template;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    deletedTemplate();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleReorder = (action) => {
    if (action === "categories") {
      dispatch(reorderCategory(true));
    } else if (action === "lines") {
      dispatch(reorderLines(true));
    }
  };
  const handleEdit = (action) => {
    if (action === "categories") {
      dispatch(editCategory(true));
    } else if (action === "lines") {
      dispatch(editLine(true));
    }
  };
  const handleEditComplete = () => {
    if (action === "categories") {
      dispatch(editCategory(false));
    } else if (action === "lines") {
      dispatch(editLine(false));
    }
  };
  const handleReorderComplete = () => {
    if (action === "categories") {
      dispatch(reorderCategory(false));
    } else if (action === "lines") {
      dispatch(reorderLines(false));
    }
  };

  const handleAddTemplate = () => {
    const { data } = storeData?.defaultTemplateList;
    setTemplateList(data);
    setAddTemplateModal(true);
    handleClose();
  };

  const handleDeleteTemplate = () => {
    handleClose();
    setDeteleteTemplateModal(true);
  };

  const handleBackAction = () => {
    if (action === "lines") {
      if (!storeData?.editLine && !storeData?.reorderLines) {
        navigate(-1);
      }
      dispatch(editCategory(false));
      dispatch(editLine(false));
      dispatch(reorderLines(false));
      dispatch(reorderCategory(false));
    } else if (action === "categories") {
      dispatch(editCategory(false));
      dispatch(editLine(false));
      dispatch(reorderCategory(false));
      dispatch(reorderLines(false));
    } else {
      navigate(-1);
    }
  };

  const deletedTemplate = () => {
    let formData = { inspection_id: inspectionId };
    getDeletedTemplateData(dispatch, formData);
    dispatch(getTemplateListCall(sessionStorage.getItem("guid")));
  };

  return (
    <div
      id="sticky_header"
      className={stickyHeader ? "sticky_header" : "header"}
      style={{
        backgroundColor: (categoryData?.is_printable === 0 || categoryData?.is_printable === false) && categoryData?.is_inspection_details !== 1 ? "#E8E8E8" : undefined,
      }}
    >
      <AddTemplateModal
        addTemplateModal={addTemplateModal}
        setAddTemplateModal={setAddTemplateModal}
        deleteTemplateModal={deleteTemplateModal}
        setDeteleteTemplateModal={setDeteleteTemplateModal}
        templateList={templateList}
        storeData={storeData}
      />
      <span className="header__name" style={{ display: "flex", alignItems: "center" }}>
        <span className="header__title" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {/* Back button */}
          {(location.pathname !== "/inspection" || storeData?.editCategory || storeData?.reorderCategory) &&
            location.pathname !== "/review" &&
            location.pathname !== "/" &&
            location.pathname !== "/report_media_library" && <ArrowBackIcon sx={{ zIndex: 9, cursor: "pointer" }} id="back_button" onClick={handleBackAction} />}
          {/* Title */}
          <span style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
            {title !== null ? title : "Untitled"}
            {(categoryData?.is_printable === 0 || categoryData?.is_printable === false) && categoryData?.is_inspection_details !== 1 && <TbPrinterOff className="print_off" />}
          </span>

          {/* Associated Category Colors */}
          {assosiatedCategoryColors?.map((color, index) => (
            <span
              key={index}
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: color,
              }}
            ></span>
          ))}
        </span>
      </span>

      <div className="header__action">
        {/* comment */}
        {path[1] !== "" && path[1] !== "review" && path[1] !== "report_media_library" && action !== "line details" && action !== "pdfElement" ? (
          storeData?.editCategory || storeData?.editLine || storeData?.reorderCategory || storeData?.reorderLines ? (
            <span
              className="header_info_text"
              onClick={() => {
                handleEditComplete();
                handleReorderComplete();
              }}
              id="done_button"
            >
              Done
            </span>
          ) : categoryData?.is_inspection_details === 2 ? (
            ""
          ) : (
            <Button id="ellipsis-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
              <MoreHorizOutlinedIcon />
            </Button>
          )
        ) : (
          ""
        )}
        <Menu
          id="header-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          className="header__menu"
        >
          <MenuItem className="list__close_icon" id="header_menu_close" onClick={handleClose}>
            <CloseIcon />
          </MenuItem>
          {!IsPdf && (
            <MenuItem
              onClick={() => {
                handleClose();
                handleReorder(action);
              }}
              id="reorder_list"
            >
              <ListItemIcon>
                <ViewHeadlineOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Reorder {action}</ListItemText>
            </MenuItem>
          )}
          {!IsPdf && (
            <MenuItem
              onClick={() => {
                handleClose();
                handleEdit(action);
              }}
              id="edit_list"
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit {action} </ListItemText>
            </MenuItem>
          )}
          {action === "categories" && (
            <MenuItem onClick={handleAddTemplate} id="add_template_in_list">
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add template </ListItemText>
            </MenuItem>
          )}
          {action === "categories" && (
            <MenuItem onClick={handleDeleteTemplate} id="remove_template_from_list">
              <ListItemIcon>
                <DeleteForeverIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Remove template </ListItemText>
            </MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default Header;
