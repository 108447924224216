import { ClickAwayListener, Grid } from "@mui/material";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import { useDispatch, useSelector } from "react-redux";
import "../../../Assets/Styles/Containers/_button.scss";
import React, { useState, useEffect, useRef, } from "react";
import "../../../Assets/Styles/Containers/_inspection.scss";
import { convertFontToSpan } from "../../Helpers/HelperFunctions";
import FroalaEditorComponent from "../../Common/HtmlEditor/FroalaEditorComponent";
import { decodeEntities, filterSelectTagIntro } from "../LineDetails/CommentDetails/CommentLibraryHelper";
import { saveIntroductoryText } from "../../Redux/Actions/CommonActions";

const froalaConfig = {
  imagePaste: false,
  spellCheck: true,
  heightMin: 201,
  heightMax: 300,
  toolbarButtons: {
    moreText: {
      buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"],
      align: "left",
      buttonsVisible: 3,
    },
    moreRich: { buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"], align: "left", buttonsVisible: 0 },
    moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
  },
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const AddIntroText = (props) => {
  const editorRef = useRef();
  const containerRef = useRef();
  const dialogRef = useRef();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const { categoryData, templateId, categoryId, } = props;
  const templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
  const categoryIndex = storeData?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
  const [introductoryDropdowns, setIntroductoryDropdowns] = useState(categoryData?.introductory_text_dropdowns || []);
  const [isCopy, setIsCopy] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isButtonAdded, setIsButtonAdded] = useState(false);
  const prevData = storeData?.inspectionData?.data;
  const introductoryText = prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.introductory_text
  const [editorContent, setEditorContent] = useState({
    innerHTML: introductoryText ? introductoryText?.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, "") : "",
  });
  const handleChange = (name, content) => {
    setEditorContent({ innerHTML: content ? content?.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, "") : "" });
    if (!isButtonAdded) { setHasChanges(true); } else { setIsButtonAdded(false); }
    setIsCopy(false);
    setIntroductoryDropdowns(introductoryDropdowns?.length ? introductoryDropdowns : categoryData?.introductory_text_dropdowns || []);
  };

  useEffect(() => {
    setIntroductoryDropdowns(categoryData?.introductory_text_dropdowns || []);
  }, [categoryData?.introductory_text_dropdowns]);

  useEffect(() => {
    const editorInstance = editorRef?.current?.editor;
    let editorData = categoryData?.introductory_text
    let isEffectExecuted = false;
    let newDropdownList = categoryData?.introductory_text_dropdowns?.length ? [...categoryData?.introductory_text_dropdowns] : [];
    let modifiedHtml;
    const replacePlaceholdersWithDropdowns = () => {
      const dropdowns = categoryData?.introductory_text_dropdowns;
      const regexPlainDropdown = /\[a-f0-9\-]+/g;
      if (!dropdowns?.some((dropdown) => categoryData?.introductory_text?.includes(dropdown?.dropdown_uuid)) || !dropdowns) {
        return categoryData?.introductory_text;
      }
      if (Array?.isArray(dropdowns) && dropdowns?.length > 0) {
        const tempContainer = document.createElement("div");
        tempContainer.innerHTML = categoryData?.introductory_text;
        dropdowns.forEach((dropdown) => {
          let optionValues = [];
          const spanElements = Array.from(tempContainer.querySelectorAll(`span[contenteditable="false"][id="[dropdown ${dropdown?.dropdown_uuid}]"]`));
          if (spanElements?.length > 0) {
            spanElements.forEach((spanElement) => {
              spanElement.innerHTML = "";
              const selectElement = document.createElement("select");
              let newDropdownValues = Array.isArray(dropdown?.dropdowns_values?.values) ? dropdown?.dropdowns_values?.values : dropdown?.dropdowns_values;
              if (Array.isArray(newDropdownValues)) {
                newDropdownValues = newDropdownValues.filter((obj) => !obj.toUpperCase().includes("XXX"));
                if (!newDropdownValues.find((value) => value.includes("------"))) {
                  newDropdownValues.unshift("-------------");
                }
                newDropdownValues.forEach((value) => {
                  const option = document.createElement("option");
                  option.text = value?.length > 40 ? `${value.substring(0, 40)}...` : value || "";
                  option.value = value || "";
                  option.title = value || "";
                  if (dropdown?.selected_value === value) {
                    option.setAttribute("selected", value);
                  }
                  selectElement.appendChild(option);
                });
              }
              selectElement.classList.add(`intro-cl-dropdown`);
              selectElement.setAttribute("id", dropdown.dropdown_uuid);
              selectElement.addEventListener("change", function (event) {
                handleChangeDropdown(event, dropdown.dropdown_uuid);
              });
              optionValues = Array.from(selectElement.options)
                .map((option) => option.value)
                .filter((value) => value !== "-------------");
              newDropdownList.push({
                dropdown_uuid: dropdown.dropdown_uuid,
                name: `dropdown ${dropdown.dropdown_uuid}`,
                selected_value: dropdown.selected_value,
                dropdowns_values: optionValues || [],
                isPasted: true,
              });
              spanElement.appendChild(selectElement);
              const buttonElement = document.createElement("button");
              buttonElement.setAttribute("id", `dropdownButton${dropdown.dropdown_uuid}`);
              buttonElement.setAttribute("contenteditable", "false");
              buttonElement.setAttribute("name", `${dropdown.dropdown_uuid}`);
              buttonElement.classList.add("dropdown-button");
              buttonElement.setAttribute("type", "button");
              buttonElement.style.cssText = `background-color: #f1f9fe;
                      width: 24px;
                      height: 24px;
                      padding: 0px;
                      color: #4282e2;
                      border-radius: 4px 0px 0px 4px;
                      border: 1px solid #4282e2;
                      position: relative;
                      transform: scaleX(-1);
                      font-size: 17px;
                      margin-left: -4px;
                      top: 2px;`;
              buttonElement.textContent = "✎";
              spanElement.appendChild(buttonElement);
              const styleTag = document.createElement("style");
              styleTag.innerHTML = `
                    @media screen and (max-width: 1100px) {
                      #dropdownButton${dropdown.dropdown_uuid} {
                        top: 25.5px;
                      }
                    }
                    @media screen and (min-width: 1600px) {
                      #dropdownButton${dropdown.dropdown_uuid} {
                        top: 2.5px;
                        width: initial;
                        margin-left: -5px;
                      }
                    }
                    @media screen and (min-width: 1700px) {
                      #dropdownButton${dropdown.dropdown_uuid} {
                        top: 2.5px;
                        width: initial;
                        margin-left: -5px;
                      }
                    }
                    @media screen and (min-width: 1800px) {
                      #dropdownButton${dropdown.dropdown_uuid} {
                        top: 2.5px;
                        width: initial;
                        margin-left: -5px;
                      }
                    }
                    @media screen and (min-width: 1900px) {
                      #dropdownButton${dropdown.dropdown_uuid} {
                        top: 2px;
                        width: initial;
                        margin-left: -5px;
                      }
                    }
                    @media screen and (min-width: 2000px) {
                      #dropdownButton${dropdown.dropdown_uuid} {
                        top: 1.5px;
                        width: initial;
                        margin-left: -5px;
                      }
                    }
                    @media screen and (min-width: 2100px) {
                      #dropdownButton${dropdown.dropdown_uuid} {
                        top: 1.5px;
                        width: initial;
                        margin-left: -5px;
                      }
                    }
                  `;
              document.head.appendChild(styleTag);
            });
          } else {
            var placeholderRegex = /\[dropdown\s([a-f0-9-]+)\]/g;
            modifiedHtml = categoryData?.introductory_text?.replace(placeholderRegex, (match, dropdownId) => {
              const dropdown = categoryData?.introductory_text_dropdowns?.find((item) => item?.dropdown_uuid === dropdownId);
              if (dropdown) {
                let optionsHtml = dropdown?.dropdowns_values?.length === 0
                  ? ""
                  : dropdown?.dropdowns_values?.filter((obj) => !obj?.toUpperCase().includes("XXX"))
                    ?.map((value) => {
                      const selectedValue = value?.length > 40 ? `${value.substring(0, 40)}...` : value;
                      const isSelected = selectedValue?.trim() === dropdown?.selected_value?.trim();
                      return `<option title="${selectedValue}" value="${selectedValue}" ${isSelected ? " selected" : ""}>${selectedValue}</option>`;
                    })
                    .join("");
                return `<span contenteditable="false" style="white-space: nowrap;" id="[dropdown ${dropdownId}]">
                      <select id="${dropdownId}" style="width: 104px;
                              background-color: #f1f9fe;
                              height: 24px;
                              overflow-wrap: break-word;
                              padding: 1px 17px 3px 6px;
                              background-position: right 4px center;
                              background-repeat: no-repeat;
                              background-size: 22px 20px;
                              border-radius: 4px 0px 0px 4px;
                              border: 1px solid #4282e2;
                              appearance: none;
                              -webkit-appearance: none;
                              -moz-appearance: none;
                              font-size: 14px !important;
                              color: #3B3B3B !important;
                              font-style: normal !important;
                              font-weight: 600 !important;
                              max-width: 200px !important;
                              min-width: 104px !important;
                              text-overflow: ellipsis !important;
                              white-space: nowrap !important;
                              width: -webkit-max-content !important;
                              width: max-content !important;
                              background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7%2010L12%2015L17%2010H7Z%22%20fill%3D%22%23777777%22/%3E%3C/svg%3E');
                              onchange="handleChangeDropdown(event, '${introductoryDropdowns}')"
                              ">
                        <option value="-------------">-------------</option>
                        ${optionsHtml}
                      </select>
                      <button id="dropdownButton${dropdownId}" contenteditable="false" name="${dropdownId}" type="button" style="background-color: #f1f9fe;
                              width: 24px;
                              height: 24px;
                              padding: 0px;
                              color: #4282e2;
                              border-radius: 4px 0px 0px 4px;
                              border: 1px solid #4282e2;
                              position: relative;
                              transform: scaleX(-1);
                              font-size: 17px;
                              margin-left: -4px;
                              display: inline-flex;
                              vertical-align: middle;
                              top: -1px;">
                        ✎
                      </button>
                    </span>`;
              }
              return match;
            });
          }
        });
        editorData = { innerHTML: tempContainer?.innerHTML }
      } else {
        const outputString = editorData?.innerHTML?.replace(regexPlainDropdown, (match, selectId) => {
          return `<select id="${selectId}" class="intro-cl-dropdown"><option>--------------</option></select>`;
        });
        editorData = { innerHTML: outputString }
      }
      return decodeEntities(editorData.innerHTML?.replace(regexPlainDropdown, ""));
    };
    const isIntroTextEmpty =
      categoryData?.introductory_text === "<p></p>" || categoryData?.introductory_text === "";
    setTimeout(async () => {
      if (isIntroTextEmpty) {
        editorInstance?.html?.set("<p></p>");
      }
      else if (categoryData?.introductory_text) {
        if (!isEffectExecuted || isCopy && editorInstance) {
          isEffectExecuted = true;
          const newHtmlData = replacePlaceholdersWithDropdowns();
          editorInstance.html.set(modifiedHtml || newHtmlData || "");
          setEditorContent({ innerHTML: modifiedHtml || newHtmlData || "" });
        }
      }
      else {
      }
    }, 100);
    return () => {
      isEffectExecuted = false;
    };
  }, [categoryData]);

  const handleIntroSave = async (textDropdowns) => {
    const latestContent = editorRef.current?.editor?.html?.get();
    const fromData = {
      "inspection_id": storeData?.inspectionData?.data?.inspection_id,
      "template_id": templateId,
      "category_uuid": categoryData?.uuid,
      "category_id": categoryId,
      "introductory_text": filterSelectTagIntro(latestContent),
      "introductory_text_dropdowns": textDropdowns?.length ? textDropdowns : introductoryDropdowns,
    }
    dispatch(saveIntroductoryText(fromData))
  }

  const saveIntroText = async () => {
    if (!hasChanges) return;
    setHasChanges(false);
    handleIntroSave()
    setIsCopy(true);
    setIsFocused(false);
  };

  const decodeEntities = (str) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = str;
    return textArea.value;
  };
  const handleChangeDropdown = async (event, introductoryDropdowns) => {
    const selectedValue = event?.target?.value?.length > 40 ? `${event?.target?.value.substring(0, 40)}...` : event?.target?.value;
    const dropdownId = event?.target?.id;
    const editorInstance = editorRef.current?.editor;
    let editorContent = editorInstance?.html?.get();
    const selectRegex = new RegExp(
      `<select[^>]*?id=["']${dropdownId}["'][\\s\\S]*?<\\/select>`,
      "g"
    );
    editorContent = editorContent?.replace(selectRegex, (selectMatch) => {
      const optionRegex = /<option([^>]*)>([^<]+)<\/option>/g;
      return selectMatch?.replace(optionRegex, (optionMatch, attributes, value) => {
        const decodedValue = decodeEntities(value.trim());
        const decodedSelectedValue = decodeEntities(selectedValue.trim());
        const isSelected = decodedValue === decodedSelectedValue;
        const updatedAttributes = attributes?.replace(/selected/g, "").trim();
        return `<option ${updatedAttributes} ${isSelected ? "selected" : ""}>${value}</option>`;
      });
    });
    editorInstance?.html?.set(editorContent);
    setEditorContent({ innerHTML: editorContent });
    let textDropdowns = introductoryDropdowns;
    let textDropdownIndex = introductoryDropdowns.findIndex(
      (dropdown) => dropdown?.dropdown_uuid && dropdown?.dropdown_uuid === dropdownId
    );
    if (textDropdownIndex !== -1) {
      textDropdowns[textDropdownIndex] = { ...textDropdowns[textDropdownIndex], selected_value: selectedValue };
      handleIntroSave(textDropdowns);
      setIntroductoryDropdowns(textDropdowns);
    }
    setHasChanges(false);
    setIsFocused(false);
  }

  let introDropElements = document.querySelectorAll(".intro-cl-dropdown");
  introDropElements.forEach((element, index) => {
    if (element) {
      element.onchange = (event) => handleChangeDropdown(event, introductoryDropdowns);
    }
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickOutside =
        containerRef.current && !containerRef.current.contains(event.target) &&
        !dialogRef?.current?.contains(event.target) &&
        event.target.id !== "add-dropdown";
      if (hasChanges && isClickOutside && prevData.inspectiontemplates[templateIndex].categories[categoryIndex].introductory_text !== editorContent?.innerHTML) {
        setHasChanges(false);
        saveIntroText();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hasChanges, editorContent]);

  useEffect(() => {
    const editorInstance = editorRef?.current?.editor;
    if (editorInstance && prevData) {
      const contentArea = editorInstance?.$el?.get(0);
      if (!contentArea) return;
      const changeDropdown = (event) => {
        handleChangeDropdown(event, introductoryDropdowns);
      };
      contentArea.removeEventListener("change", changeDropdown);
      contentArea.addEventListener("change", changeDropdown);
      return () => {
        contentArea.removeEventListener("change", changeDropdown);
      };
    }
  }, [editorRef, prevData, introductoryDropdowns]);

  useEffect(() => {
    const changeDropdown = (event) => {
      handleChangeDropdown(event, introductoryDropdowns);
    };
    document.removeEventListener("changeValue", changeDropdown);
    document.addEventListener("changeValue", changeDropdown);
    return () => {
      document.removeEventListener("changeValue", changeDropdown);
    };
  }, [editorRef, prevData, introductoryDropdowns]);

  const handleDropdownList = (name, value) => {
    if (name === "intro-text-editor") {
      setIntroductoryDropdowns(value);
      console.log("handleDropdownList", name, value);
      handleIntroSave();
    }
  }

  useEffect(() => {
    setEditorContent({
      innerHTML: categoryData?.introductory_text === "" ? "<p></p>" : categoryData?.introductory_text.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, ""),
    });
    // setIntroTextMedia(categoryData?.introductory_text_media);
  }, [categoryData]);

  return (
    <Grid container className="input-controll" ref={containerRef}>
      <Grid item xs={12} sm={12} sx={{ padding: "8px 16px 16px 16px" }}>
        <div>
          <FroalaEditorComponent
            copy={isCopy}
            showAI={false}
            commentData={categoryData}
            editorData={convertFontToSpan(editorContent)}
            setEditorContent={setEditorContent}
            editor={editorRef}
            id={"intro-text-editor"}
            onChange={handleChange}
            froalaConfig={froalaConfig}
            handleDropdownList={handleDropdownList}
            dialogRef={dialogRef}
            templateId={templateId}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            setHasChanges={setHasChanges}
            setIsButtonAdded={setIsButtonAdded}
            isEdit={true}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default AddIntroText;
