const ACTION_TYPES = {
  GET_INSPECTION_DATA: "GET_INSPECTION_DATA",
  GET_INSPECTION_DATA_WITHOUT_LOADER: "GET_INSPECTION_DATA_WITHOUT_LOADER",
  SET_INSPECTION_DATA: "SET_INSPECTION_DATA",
  GET_INSPECTION_RESPONSE: "GET_INSPECTION_RESPONSE",
  GET_INSPECTION_RESPONSE_FOR_BULK_MEDIA: "GET_INSPECTION_RESPONSE_FOR_BULK_MEDIA",
  GET_INSPECTION_LOADER: "GET_INSPECTION_LOADER",
  GET_ADD_LINE_LOADER: "GET_ADD_LINE_LOADER",
  GET_ADD_CAT_LOADER: "GET_ADD_CAT_LOADER",
  GET_LOADER_FOR_TEMPLATE_UPDATE: "GET_LOADER_FOR_TEMPLATE_UPDATE",
  GET_INSPECTION_ERROR: "GET_INSPECTION_ERROR",
  LOAD_DASHBOARD_REQUEST: "LOAD_DASHBOARD_REQUEST",
  LOAD_DASHBOARD_RESPONSE: "LOAD_DASHBOARD_RESPONSE",
  LOAD_DASHBOARD_ERROR: "LOAD_DASHBOARD_ERROR",
  // REORDER_CATEGORY: "REORDER_CATEGORY",
  // update inspection
  UPDATE_INSPECTION_DATA: "UPDATE_INSPECTION_DATA",
  DELETE_INSPECTION_MEDIA: "DELETE_INSPECTION_MEDIA",
  DELETE_INSPECTION_MEDIA_RESPONSE: "DELETE_INSPECTION_MEDIA_RESPONSE",
  UPDATE_INSPECTION_DATA_WITH_LOADER: "UPDATE_INSPECTION_DATA_WITH_LOADER",
  UPDATE_INSPECTION_DATA_FOR_ADD_INP: "UPDATE_INSPECTION_DATA_FOR_ADD_INP",
  UPDATE_INSPECTION_RESPONSE: "UPDATE_INSPECTION_RESPONSE",
  UPDATE_INSPECTION_ERROR: "UPDATE_INSPECTION_ERROR",
  UPDATE_INSPECTION_RESPONSE_FOR_ADD_INP: "UPDATE_INSPECTION_RESPONSE_FOR_ADD_INP",
  UPDATE_INSPECTION_ERROR_FOR_ADD_INP: "UPDATE_INSPECTION_ERROR_FOR_ADD_INP",
  SEND_DATA_TO_READ_SECTION_OFFLINE: "SEND_DATA_TO_READ_SECTION_OFFLINE",
  CLEAR_UPDATED_INSPECTION_DATA: "CLEAR_UPDATED_INSPECTION_DATA",
  CLEAR_UPDATED_TEMPLATE_DATA: "CLEAR_UPDATED_TEMPLATE_DATA",
  CLEAR_INSPECTION_DATA_ERROR: "CLEAR_INSPECTION_DATA_ERROR",
  // get category list and line list
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  GET_CATEGORY_LIST_RESPONSE: "GET_CATEGORY_LIST_RESPONSE",
  GET_CATEGORY_LIST_ERROR: "GET_CATEGORY_LIST_ERROR",
  GET_CATEGORY_LINE_LIST: "GET_CATEGORY_LINE_LIST",
  // add and remove templates
  SWAP_INSPECTION_DETAILS: "SWAP_INSPECTION_DETAILS",
  SWAP_INSPECTION_DETAILS_RESPONSE: "SWAP_INSPECTION_DETAILS_RESPONSE",
  SWAP_INSPECTION_DETAILS_ERROR: "SWAP_INSPECTION_DETAILS_ERROR",
  GET_DELETED_TEMPLATE_DATA: "GET_DELETED_TEMPLATE_DATA",
  GET_DELETED_TEMPLATE_DATA_RESPONSE: "GET_DELETED_TEMPLATE_DATA_RESPONSE",
  GET_DELETED_TEMPLATE_DATA_ERROR: "GET_DELETED_TEMPLATE_DATA_ERROR",

  VALIDATE_CATGEORY_LINE_RELATION: "VALIDATE_CATGEORY_LINE_RELATION",
  VALIDATE_CATGEORY_LINE_RELATION_RESPONSE: "VALIDATE_CATGEORY_LINE_RELATION_RESPONSE",
  VALIDATE_CATGEORY_LINE_RELATION_ERROR: "VALIDATE_CATGEORY_LINE_RELATION_ERROR",

  GET_TEMPLATE_LIST: "GET_TEMPLATE_LIST",
  GET_TEMPLATE_LIST_RESPONSE: "GET_TEMPLATE_LIST_RESPONSE",
  GET_TEMPLATE_LIST_ERROR: "GET_TEMPLATE_LIST_ERROR",
  GET_CATEGORY_LINE_LIST_RESPONSE: "GET_CATEGORY_LINE_LIST_RESPONSE",
  GET_CATEGORY_LINE_LIST_ERROR: "GET_CATEGORY_LINE_LIST_ERROR",
  GET_CATEGORY_LIST_DATA: "GET_CATEGORY_LIST_DATA",
  GET_CATEGORY_LIST_DATA_SUCCESS: "GET_CATEGORY_LIST_DATA_SUCCESS",
  GET_CATEGORY_LIST_DATA_ERROR: "GET_CATEGORY_LIST_DATA_ERROR",
  GET_MASTER_TEMPLATE_DATA: "GET_MASTER_TEMPLATE_DATA",
  GET_MASTER_TEMPLATES_SUCCESS: "GET_MASTER_TEMPLATES_SUCCESS",
  GET_MASTER_TEMPLATES_ERROR: "GET_MASTER_TEMPLATES_ERROR",
  UPDATE_ISN_TEMPLATE_DATA: "UPDATE_ISN_TEMPLATE_DATA",
  UPDATE_ISN_TEMPLATE_RESPONSE: "UPDATE_ISN_TEMPLATE_RESPONSE",
  UPDATE_ISN_TEMPLATE_ERROR: "UPDATE_ISN_TEMPLATE_ERROR",

  // media
  UPLOAD_MEDIA: "UPLOAD_MEDIA",
  UPLOAD_MEDIA_RESPONSE: "UPLOAD_MEDIA_RESPONSE",
  UPLOAD_MEDIA_ERROR: "UPLOAD_MEDIA_ERROR",
  //bulk upload
  BULK_UPLOAD: "BULK_UPLOAD",
  BULK_UPLOAD_RESPONSE: "BULK_UPLOAD_RESPONSE",
  BULK_UPLOAD_ERROR: "BULK_UPLOAD_ERROR",
  // attachment
  UPLOAD_ATTACHMENT: "UPLOAD_ATTACHMENT",
  UPLOAD_ATTACHMENT_RESPONSE: "UPLOAD_ATTACHMENT_RESPONSE",
  UPLOAD_ATTACHMENT_ERROR: "UPLOAD_ATTACHMENT_ERROR",
  //markComplete
  MARK_COMPLETE: "MARK_COMPLETE",
  MARK_COMPLETE_RESPONSE: "MARK_COMPLETE_RESPONSE",
  MARK_COMPLETE_ERROR: "MARK_COMPLETE_ERROR",
  PORCH_PDF_GENERATE: "PORCH_PDF_GENERATE",
  PORCH_PDF_GENERATE_RESPONSE: "PORCH_PDF_GENERATE_RESPONSE",
  PORCH_PDF_GENERATE_ERROR: "PORCH_PDF_GENERATE_ERROR",
  // ISN Mark complete
  MARK_COMPLETE_ISN: "MARK_COMPLETE_ISN",
  MARK_COMPLETE_RESPONSE_ISN: "MARK_COMPLETE_RESPONSE_ISN",
  MARK_COMPLETE_ERROR_ISN: "MARK_COMPLETE_ERROR_ISN",
  MARK_COMPLETE_ISN_SEND_REPORT: "MARK_COMPLETE_ISN_SEND_REPORT",
  MARK_COMPLETE_ISN_SEND_REPORT_RESPONSE: "MARK_COMPLETE_ISN_SEND_REPORT_RESPONSE",
  MARK_COMPLETE_ISN_SEND_REPORT_ERROR: "MARK_COMPLETE_ISN_SEND_REPORT_ERROR",
  // scroll to top
  SCROLL_TO_TOP: "SCROLL_TO_TOP",
  SCROLL_PAGE_TOP: "SCROLL_PAGE_TOP",
  //static background
  STATIC_BACKGROUND: "STATIC_BACKGROUND",
  SET_STATIC_BACKGROUND: "SET_STATIC_BACKGROUND",
  // edit categories or lines
  EDIT_CATEGORY: "EDIT_CATEGORY",
  EDIT_CAT_STATUS: "EDIT_CAT_STATUS",
  EDIT_LINE: "EDIT_LINE",
  EDIT_LINE_STATUS: "EDIT_LINE_STATUS",
  // reoder categroeis lines
  REORDER_CATEGORY: "REORDER_CATEGORY",
  REORDER_CATEGORY_STATUS: "REORDER_CATEGORY_STATUS",
  BULK_UPLOAD_MEDIA_LENGTH: "BULK_UPLOAD_MEDIA_LENGTH",
  SUCCESSFUL_BULK_UPLOAD_MEDIA_LENGTH: "SUCCESSFUL_BULK_UPLOAD_MEDIA_LENGTH",
  FAIL_BULK_UPLOAD_MEDIA_LENGTH: "FAIL_BULK_UPLOAD_MEDIA_LENGTH",
  FAIL_BULK_UPLOAD_MEDIA_LENGTH_STATUS: "FAIL_BULK_UPLOAD_MEDIA_LENGTH_STATUS",
  FAILED_BULK_UPLOAD_MEDIA_STATUS: "FAILED_BULK_UPLOAD_MEDIA_STATUS",
  FAILED_BULK_UPLOAD_MEDIA: "FAILED_BULK_UPLOAD_MEDIA",
  SUCCESSFUL_BULK_UPLOAD_MEDIA_LENGTH_STATUS: "SUCCESSFUL_BULK_UPLOAD_MEDIA_LENGTH_STATUS",
  BULK_UPLOAD_MEDIA_LENGTH_STATUS: "BULK_UPLOAD_MEDIA_LENGTH_STATUS",
  BULK_UPLOADING: "BULK_UPLOADING",
  BULK_UPLOADING_STAUS: "BULK_UPLOADING_STATUS",
  REORDER_LINES: "REORDER_LINES",
  REORDER_LINES_STATUS: "REORDER_LINES_STATUS",
  // activate lines and categories
  ACTIVATE_LINE: "ACTIVATE_LINE",
  ACTIVATE_CURRENT_LINE: "ACTIVATE_CURRENT_LINE",
  CLEAR_ACTIVE_LINE: "CLEAR_ACTIVE_LINE",
  CLEAR_ACTIVATED_CURRENT_LINE: "CLEAR_ACTIVATED_CURRENT_LINE",
  // duplicate line
  DUPLICATE_LINES: "DUPLICATE_LINES",
  DUPLICATE_LINES_RESPONSE: "DUPLICATE_LINES_RESPONSE",
  DUPLICATE_LINES_ERROR: "DUPLICATE_LINES_ERROR",

  // duplicate category
  DUPLICATE_CATEGORYS: "DUPLICATE_CATEGORYS",
  DUPLICATE_CATEGORYS_RESPONSE: "DUPLICATE_CATEGORYS_RESPONSE",
  DUPLICATE_CATEGORYS_ERROR: "DUPLICATE_CATEGORYS_ERROR",
  // assign inspector flag to category
  ASSIGN_INSPECTOR_FLAG_TO_CATEGORY: "ASSIGN_INSPECTOR_FLAG_TO_CATEGORY",
  ASSIGN_INSPECTOR_FLAG_TO_CATEGORY_RESPONSE: "ASSIGN_INSPECTOR_FLAG_TO_CATEGORY_RESPONSE",
  ASSIGN_INSPECTOR_FLAG_TO_CATEGORY_ERROR: "ASSIGN_INSPECTOR_FLAG_TO_CATEGORY_ERROR",
  ASSIGN_INSPECTOR_FLAG_TO_LINE: "ASSIGN_INSPECTOR_FLAG_TO_LINE",
  ASSIGN_INSPECTOR_FLAG_TO_LINE_RESPONSE: "ASSIGN_INSPECTOR_FLAG_TO_LINE_RESPONSE",
  ASSIGN_INSPECTOR_FLAG_TO_LINE_ERROR: "ASSIGN_INSPECTOR_FLAG_TO_LINE_ERROR",
  // edit inspector flag list
  EDIT_INSPECTOR_FLAG_LIST: "EDIT_INSPECTOR_FLAG_LIST",
  EDIT_INSPECTOR_FLAG_LIST_RESPONSE: "EDIT_INSPECTOR_FLAG_LIST_RESPONSE",
  EDIT_INSPECTOR_FLAG_LIST_ERROR: "EDIT_INSPECTOR_FLAG_LIST_ERROR",
  // introtext media
  INTRODUCTORY_TEXT_MEDIA: "INTRODUCTORY_TEXT_MEDIA",
  INTRODUCTORY_TEXT_MEDIA_RESPONSE: "INTRODUCTORY_TEXT_MEDIA_RESPONSE",
  INTRODUCTORY_TEXT_MEDIA_ERROR: "INTRODUCTORY_TEXT_MEDIA_ERROR",
  // comment library
  SHOW_COMMENT_LIBRARY_MODAL: "SHOW_COMMENT_LIBRARY_MODAL",
  SHOW_COMMENT_LIBRARY_MODAL_RESPONSE: "SHOW_COMMENT_LIBRARY_MODAL_RESPONSE",
  GET_COMMENT_LIBRARY_LIST: "GET_COMMENT_LIBRARY_LIST",
  GET_COMMENT_LIBRARY_LIST_RESPONSE: "GET_COMMENT_LIBRARY_LIST_RESPONSE",
  GET_COMMENT_LIBRARY_LIST_ERROR: "GET_COMMENT_LIBRARY_LIST_ERROR",
  GET_COMMENT_LIBRARY_LIST_LOADER: "GET_COMMENT_LIBRARY_LIST_LOADER",
  ADD_COMMENT_FROM_CL_TO_REPORT: "ADD_COMMENT_FROM_CL_TO_REPORT",
  ADD_COMMENT_TOREPORT_FROM_CL_RESPONSE: "ADD_COMMENT_TOREPORT_FROM_CL_RESPONSE",
  ADD_COMMENT_TOREPORT_FROM_CL_ERROR: "ADD_COMMENT_TOREPORT_FROM_CL_ERROR",
  UPDATE_CL_COMMENT_STATUS: "UPDATE_CL_COMMENT_STATUS",
  UPDATE_CL_COMMENT_STATUS_RESPONSE: "UPDATE_CL_COMMENT_STATUS_RESPONSE",
  UPDATE_CL_COMMENT_STATUS_ERROR: "UPDATE_CL_COMMENT_STATUS_ERROR",
  ADD_NEW_NARRATIVE_COMMENT: "ADD_NEW_NARRATIVE_COMMENT",
  ADD_NEW_NARRATIVE_COMMENT_RESPONSE: "ADD_NEW_NARRATIVE_COMMENT_RESPONSE",
  ADD_NEW_NARRATIVE_COMMENT_ERROR: "ADD_NEW_NARRATIVE_COMMENT_ERROR",
  GET_COMMENT_LIBRARY_TAG_LIST: "GET_COMMENT_LIBRARY_TAG_LIST",
  GET_COMMENT_LIBRARY_TAG_LIST_RESPONSE: "GET_COMMENT_LIBRARY_TAG_LIST_RESPONSE",
  GET_COMMENT_LIBRARY_TAG_LIST_ERROR: "GET_COMMENT_LIBRARY_TAG_LIST_ERROR",
  GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST: "GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST",
  GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST_RESPONSE: "GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST_RESPONSE",
  GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST_ERROR: "GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST_ERROR",
  UPDATE_DROPDOWNVALUES: "UPDATE_DROPDOWNVALUES",
  UPDATE_DROPDOWNVALUES_RESPONSE: "UPDATE_DROPDOWNVALUES_RESPONSE",
  UPDATE_DROPDOWNVALUES_ERROR: "UPDATE_DROPDOWNVALUES_ERROR",
  // report library
  TOGGLE_REPORT_SIDEBAR: "TOGGLE_REPORT_SIDEBAR",
  UPDATE_REPORT_SIDEBAR_STATUS: "UPDATE_REPORT_SIDEBAR_STATUS",
  SAVE_INTRO_TEXT: "SAVE_INTRO_TEXT",
  SAVE_INTRO_TEXT_RESPONSE: "SAVE_INTRO_TEXT_RESPONSE",
  SAVE_INTRO_TEXT_ERROR: "SAVE_INTRO_TEXT_ERROR",
};

export default ACTION_TYPES;
