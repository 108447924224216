import { takeEvery } from "redux-saga/effects";
import ACTION_TYPES from "../Constants/ActionTypes";
import {
  setInspectionData,
  updateInspectionData,
  updateInspectionDataForAddInp,
  uploadMedia,
  markReportComplete,
  markReportCompleteIsn,
  markReportCompleteIsnSendReport,
  getCategoryList,
  editCategoryStatus,
  editLineStatus,
  getAllCategoryListData,
  getMasterTemplateData,
  updateISNTemplateData,
  uploadAttachment,
  updateInspectionDataWithLoader,
  getCategoryLineList,
  reorderCategoryStatus,
  reorderLinesStatus,
  scroPageToTop,
  activateLine,
  clearActivatedLine,
  getTemplateList,
  swapInspectionDetails,
  deletedTemplateData,
  staticBackGround,
  duplicateLine,
  duplicateCategory,
  gerenaratePorchCall,
  setInspectionDataWithOutLoader,
  getcommentLibraryList,
  addCommentsToReportFromCl,
  updateClCommentStatus,
  addNewNarrativeComment,
  getCLTagList,
  getClSummaryTagList,
  uploadBulkMedia,
  bulkUploadMediaStatus,
  updateDropdownValues,
  successfulbulkUploadMediaStatus,
  failBulkUploadMediaStatus,
  toggleReportSidebar,
  bulkUploadingStatus,
  failedBulkUploadMediaStatus,
  updateInspectionMediaData,
  showCommentModal,
  assignInspectorFlagToCategory,
  editInspectorFlagList,
  assignInspectorFlagToLine,
  categoryLineRelation,
  introductoryTextMedia,
  updateIntroValues,
} from "./Dashboard";

export default function* rootSagaChild() {
  // yield takeEvery(ACTION_TYPES.LOAD_DASHBOARD_REQUEST,setDashboadrData)
  yield takeEvery(ACTION_TYPES.GET_INSPECTION_DATA, setInspectionData);
  yield takeEvery(ACTION_TYPES.GET_INSPECTION_DATA_WITHOUT_LOADER, setInspectionDataWithOutLoader);
  yield takeEvery(ACTION_TYPES.UPDATE_INSPECTION_DATA, updateInspectionData);
  yield takeEvery(ACTION_TYPES.DELETE_INSPECTION_MEDIA, updateInspectionMediaData);
  yield takeEvery(ACTION_TYPES.UPDATE_INSPECTION_DATA_WITH_LOADER, updateInspectionDataWithLoader);
  yield takeEvery(ACTION_TYPES.UPDATE_INSPECTION_DATA_FOR_ADD_INP, updateInspectionDataForAddInp);
  yield takeEvery(ACTION_TYPES.UPLOAD_MEDIA, uploadMedia);
  yield takeEvery(ACTION_TYPES.BULK_UPLOAD, uploadBulkMedia);
  yield takeEvery(ACTION_TYPES.UPLOAD_ATTACHMENT, uploadAttachment);
  yield takeEvery(ACTION_TYPES.DUPLICATE_LINES, duplicateLine);
  yield takeEvery(ACTION_TYPES.DUPLICATE_CATEGORYS, duplicateCategory);
  yield takeEvery(ACTION_TYPES.ASSIGN_INSPECTOR_FLAG_TO_CATEGORY, assignInspectorFlagToCategory);
  yield takeEvery(ACTION_TYPES.ASSIGN_INSPECTOR_FLAG_TO_LINE, assignInspectorFlagToLine);
  yield takeEvery(ACTION_TYPES.EDIT_INSPECTOR_FLAG_LIST, editInspectorFlagList);
  yield takeEvery(ACTION_TYPES.INTRODUCTORY_TEXT_MEDIA, introductoryTextMedia);
  yield takeEvery(ACTION_TYPES.MARK_COMPLETE, markReportComplete);
  yield takeEvery(ACTION_TYPES.MARK_COMPLETE_ISN, markReportCompleteIsn);
  yield takeEvery(ACTION_TYPES.MARK_COMPLETE_ISN_SEND_REPORT, markReportCompleteIsnSendReport);
  yield takeEvery(ACTION_TYPES.PORCH_PDF_GENERATE, gerenaratePorchCall);
  yield takeEvery(ACTION_TYPES.GET_CATEGORY_LIST, getCategoryList);
  yield takeEvery(ACTION_TYPES.GET_CATEGORY_LINE_LIST, getCategoryLineList);
  yield takeEvery(ACTION_TYPES.GET_CATEGORY_LIST_DATA, getAllCategoryListData);
  yield takeEvery(ACTION_TYPES.ACTIVATE_LINE, activateLine);
  yield takeEvery(ACTION_TYPES.CLEAR_ACTIVE_LINE, clearActivatedLine);
  yield takeEvery(ACTION_TYPES.GET_MASTER_TEMPLATE_DATA, getMasterTemplateData);
  yield takeEvery(ACTION_TYPES.UPDATE_ISN_TEMPLATE_DATA, updateISNTemplateData);
  yield takeEvery(ACTION_TYPES.SCROLL_TO_TOP, scroPageToTop);
  yield takeEvery(ACTION_TYPES.STATIC_BACKGROUND, staticBackGround);
  yield takeEvery(ACTION_TYPES.EDIT_CATEGORY, editCategoryStatus);
  yield takeEvery(ACTION_TYPES.EDIT_LINE, editLineStatus);
  yield takeEvery(ACTION_TYPES.REORDER_CATEGORY, reorderCategoryStatus);
  yield takeEvery(ACTION_TYPES.BULK_UPLOAD_MEDIA_LENGTH, bulkUploadMediaStatus);
  yield takeEvery(ACTION_TYPES.BULK_UPLOADING, bulkUploadingStatus);
  yield takeEvery(ACTION_TYPES.SUCCESSFUL_BULK_UPLOAD_MEDIA_LENGTH, successfulbulkUploadMediaStatus);
  yield takeEvery(ACTION_TYPES.FAIL_BULK_UPLOAD_MEDIA_LENGTH, failBulkUploadMediaStatus);
  yield takeEvery(ACTION_TYPES.FAILED_BULK_UPLOAD_MEDIA, failedBulkUploadMediaStatus);
  yield takeEvery(ACTION_TYPES.REORDER_LINES, reorderLinesStatus);
  yield takeEvery(ACTION_TYPES.GET_TEMPLATE_LIST, getTemplateList);
  yield takeEvery(ACTION_TYPES.SWAP_INSPECTION_DETAILS, swapInspectionDetails);
  yield takeEvery(ACTION_TYPES.GET_DELETED_TEMPLATE_DATA, deletedTemplateData);
  yield takeEvery(ACTION_TYPES.VALIDATE_CATGEORY_LINE_RELATION, categoryLineRelation);
  yield takeEvery(ACTION_TYPES.GET_COMMENT_LIBRARY_LIST, getcommentLibraryList);
  yield takeEvery(ACTION_TYPES.ADD_COMMENT_FROM_CL_TO_REPORT, addCommentsToReportFromCl);
  yield takeEvery(ACTION_TYPES.UPDATE_CL_COMMENT_STATUS, updateClCommentStatus);
  yield takeEvery(ACTION_TYPES.ADD_NEW_NARRATIVE_COMMENT, addNewNarrativeComment);
  yield takeEvery(ACTION_TYPES.GET_COMMENT_LIBRARY_TAG_LIST, getCLTagList);
  yield takeEvery(ACTION_TYPES.GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST, getClSummaryTagList);
  yield takeEvery(ACTION_TYPES.UPDATE_DROPDOWNVALUES, updateDropdownValues);
  yield takeEvery(ACTION_TYPES.TOGGLE_REPORT_SIDEBAR, toggleReportSidebar);
  yield takeEvery(ACTION_TYPES.SHOW_COMMENT_LIBRARY_MODAL, showCommentModal);
  yield takeEvery(ACTION_TYPES.SAVE_INTRO_TEXT, updateIntroValues);
}
