import { Collapse, FormControl, Grid, Menu, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Modal } from "@mui/material";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Header from "../../Components/Header/Header";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FlagIcon from "@mui/icons-material/Flag";
import ImageIcon from "@mui/icons-material/Image";
import VideocamIcon from "@mui/icons-material/Videocam";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Search, SearchIconWrapper, StyledInputBase } from "../../Utils/services";
import moment from "moment";
import exifr from "exifr";
import { Base64 } from "js-base64";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "@mui/material";
import "../../../Assets/Styles/Containers/_lines.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  checkLineComplete,
  RatingCheck,
  videoCheck,
  mediaCheck,
  checkInspecLineComplete,
  getImageDimensions,
  resizeImage,
  checkDescriptionDisabled,
  filterObjectsUptoLine,
  filterObjectsUptoCategory,
  narrativeCommentCount,
  narrativeRatingCheck,
  allInspectorFlagging,
} from "../../Helpers/HelperFunctions";
import { useState, useEffect, useRef } from "react";
import AddItemButton from "../../Components/Button/AddItemButton";
import AddLineModal from "../../Components/Lines/AddLineModal";
import LineDescription from "../../Components/Lines/LineDescription";
import InspectionDetailsLineDescription from "../../Components/Lines/InspectionDetailsLineDescription";
import { activateLines, clearUpdatedInspectionData, getInspectionData, setShowCommentLibraryModal } from "../../Redux/Actions/CommonActions";
import { toast } from "react-hot-toast";
import AddIntroText from "../../Components/Lines/AddIntroText";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { BorderLinearProgress } from "../../Common/MuiStyles/MuiStyles";
import CloseIcon from "@mui/icons-material/Close";
import "../../../Assets/Styles/Containers/_modal.scss";
import { v4 as uuidv4 } from "uuid";
import OutlinedButton from "../../Components/Button/OutlinedButton";
import FilledButton from "../../Components/Button/FilledButton";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import { UpdateTemplateData, UpdateTemplateDataSpecific, categoryLineRelation, getDefaultLines } from "../../Helpers/ApiCall";
import Preloader from "../../Helpers/Preloader";
import PreloaderWithText from "../../Helpers/PreloaderWithText";
import AddOptionalNdStandardLineModal from "../../Components/Lines/AddOptionalNdStandardLineModal";
import CreateNewLineModal from "../../Components/Lines/CreateNewLineModal";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ReorderIcon from "@mui/icons-material/Reorder";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { origin } from "../../Config/Api-constants";
import Compressor from "compressorjs";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import axios from "axios";
import PdfCategories from "../../Components/PdfFormFill/PdfCategories";
import MessageIcon from "@mui/icons-material/Message";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteConfirmationDialog from "../../Common/Alert/DeleteConfirmationDialog";
//sidebar
import ReportLibrarySideBar from "../ReportLibrary/ReportLibrarySideBar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { AiOutlineCopy } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdColorPalette } from "react-icons/io";
import AssignColorIndicatorModal from "../Inspection/AssignColorIndicatorModal";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { TbPrinterOff } from "react-icons/tb";

const Lines = ({ onDragtoggle, setOnDragToggle, selectedMediaFiles, setSelectedMediaFiles, selectedImages, setSelectedImages }) => {
  let responseCount = 0;
  let videoUploadCount = 0;
  let imageuploadCount = 0;
  let successCount = 0;
  let errorCount = 0;
  let sizeErrorCount = 0;
  const [templateData, setTemplateData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [pdfCategoryData, setPdfCategoryData] = useState();
  const [pdfTemplateData, setPdfTemplateData] = useState();
  const [introText, setIntroText] = useState();
  const [showIntroTextModal, setShowIntroTextModal] = useState(false);
  const [activeDeleteLine, setActiveDeleteLine] = useState();
  const [deleteLineModal, setShowDeleteLineModal] = useState(false);
  const [inspectionResponse, setInspectionResponse] = useState();
  const [selectedLines, setSelectedLines] = useState([]);
  const [newLineList, setNewLineList] = useState([]);
  const [render, setReRender] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loaderText, setLoaderText] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [dragActiveText, setDragActiveText] = useState("");
  const [dragActiveuuid, setDragActiveuuid] = useState("");
  const [showEditLineModal, setShowEditLineModal] = useState(false);
  const [activeEditLine, setActiveEditLine] = useState(false);
  const [editLineName, setEditLineName] = useState("");
  const [dragAndDropFile, setDragAndDropFile] = useState([]);
  const [lineMediaData, setLineMediaData] = useState("");
  const [image, setImage] = useState("");
  const [imageInputClickedLineUuid, setImageClickedLineUuid] = useState("");
  const [editLineNameToastMessage, setEditLineNameToastMessage] = useState(false);
  const [deleteLineToastMessage, setDeleteLineToastMessage] = useState(false);
  const [reorderLineToastMessage, setReorderLineToastMessage] = useState(false);
  const [duplicateLineHighlighted, setDuplicateLineHighlighted] = useState(false);
  const [duplicateLineIdx, setDuplicateLineIdx] = useState();
  const [narrativeLineMenuAnchorEl, setNarrativeLineMenuAnchorEl] = useState(null);
  const [assignColorIndicatorNarrativeLine, setAssignColorIndicatorNarrativeLine] = useState();
  const [assignColorIndicatorModal, setAssignColorIndicatorModal] = useState(false);
  const isNarrativeLineMenuOpen = Boolean(narrativeLineMenuAnchorEl);
  const fileInputLine = useRef();
  // reorder
  const [catoutput, setCatOutput] = useState([]);
  useEffect(() => {
    if (categoryData?.lines) {
      setLoader(false);
    }
  }, [categoryData]);
  useEffect(() => {
    setCatOutput(categoryData?.lines);
  }, [categoryData]);

  const location = useLocation();
  const navigate = useNavigate();
  let isNarrative = templateData?.is_narrative_template ? true : false;
  // redux
  const dispatch = useDispatch();
  // let categoryData = location?.state.category;
  // get category id of clicked template from sidemenu
  let isPDF = location?.state?.isPDF;
  // let catgoriesData=location?.state?.pdfcategory
  let templateId = location?.state?.template_id;
  let categoryId = location?.state?.category_id;
  let templateGuid = location?.state?.template_guid;
  // let assosiatedCategoryColors = location?.state?.assosiatedCategoryColors;
  const storeData = useSelector((state) => state.CommonReducer);
  const assignColorList = storeData?.inspectionData?.data?.inspectorFlagList?.filter((item) => item.is_deleted !== 1) || [];
  const matches = useMediaQuery("(max-width:900px)");
  let relationcategoryData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.categories?.find((category) => category.id === categoryId);
  const [showLineModal, setShowLineModal] = useState(false);
  const [showInspLineModal, setShowInspLineModal] = useState(false);
  const [showCreateNewLineModal, setShowCreateNewLineModal] = useState(false);
  const [expand, setExpand] = useState(true);

  const ratingData = (ratings) => {
    return ratings.map((rating, index) => (
      <span key={index} className="rating_square_wrapper">
        <span className="rating_square" style={{ background: `${rating.Color}` }}>
          {rating.include_in_summary == "1" && <FlagIcon />}
        </span>
        <span className="rating_name_text">{rating.Name}</span>
      </span>
    ));
  };
  const narrativeRatingData = (ratings) => {
    return ratings?.map((rating, index) => (
      <span key={index} className="rating_square_wrapper">
        <span className="rating_square" style={{ background: `${rating.color}` }}>
          {rating?.include_in_summary == "1" && <FlagIcon />}
        </span>
        <span className="rating_name_text">{rating?.name}</span>
      </span>
    ));
  };

  const imageData = (images) => {
    return (
      <span className="isFlex line_media_spacing media_text">
        <ImageIcon /> {images.length} photos
      </span>
    );
  };
  const commentData = (comments) => {
    return (
      <span className="isFlex line_media_spacing media_text">
        <MessageIcon /> {comments?.length} comments
      </span>
    );
  };
  const videoData = (videos) => {
    return (
      <span className="isFlex line_media_spacing media_text">
        <VideocamIcon /> {videos.length} videos
      </span>
    );
  };

  const handleAddLine = (isInspDetails, parentUuid) => {
    if (isInspDetails) {
      setShowInspLineModal(true);
    } else {
      if (parentUuid !== null) {
        getDefaultLines(dispatch, {
          templateGuid: storeData.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.template_uuid,
          parentUuid: storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.categories?.find((category) => category.id === categoryId)
            ?.parent_uuid,
        });
      } else {
        setNewLineList([]);
      }
      setShowLineModal(true);
    }

    setSelectedLines([]);
  };
  const handleDeleteLine = (line) => {
    setShowDeleteLineModal(true);
    setActiveDeleteLine(line);
  };

  const handleEditLine = (line) => {
    setShowEditLineModal(true);
    setActiveEditLine(line);
    setEditLineName(line.prompt);
  };

  const handleCloseDeleteAlert = () => {
    setShowDeleteLineModal(false);
  };

  const handleCloseEditAlert = () => {
    setShowEditLineModal(false);
  };

  const activateLine = (uuid) => {
    dispatch(activateLines(uuid));
  };

  const handleNarrativeMoreIconClick = (event, line, index) => {
    setAssignColorIndicatorNarrativeLine(line);
    setNarrativeLineMenuAnchorEl(event.currentTarget);
  };

  const handleNarrativeMenuClose = () => {
    setNarrativeLineMenuAnchorEl(null);
  };

  const handleConfirmDeleteLine = async () => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == activeDeleteLine.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].is_deleted = 1;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines?.map(async (item, index) => {
      item.order = index + 1;
      item.updated_at = await GetUTCTime();
      return item;
    });
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].updated_at = await GetUTCTime();
    setDeleteLineToastMessage(true);
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowDeleteLineModal(false);
    setNarrativeLineMenuAnchorEl(null);
  };

  const handleConfirmEditLine = async (ediedLine) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == activeEditLine.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[
      lineIndex
    ].prompt = ediedLine;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[
      lineIndex
    ].updated_at = await GetUTCTime();
    setEditLineNameToastMessage(true);
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowEditLineModal(false);
    setNarrativeLineMenuAnchorEl(null);
  };

  const handleToggleCompleted = async (line) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == line.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].is_completed = line?.is_completed === true || line?.is_completed === 1 ? false : true;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setNarrativeLineMenuAnchorEl(null);
  };
  const handleTogglePrintable = async (line) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == line.uuid);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].is_printable = line?.is_printable === true || line?.is_printable === 1 ? false : true;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setNarrativeLineMenuAnchorEl(null);
  };

  const allAssociatedCategoryColors = (category, assignColorList) => {
    const { catwiseFlagListData } = allInspectorFlagging(inspectionResponse?.inspectiontemplates);
    const categoryData = catwiseFlagListData.find((item) => item?.category_id === category?.uuid);
    if (!categoryData?.inspector_flagging_guids || categoryData?.inspector_flagging_guids?.length === 0) {
      return [];
    }
    const uniqueGuids = [...new Set(categoryData.inspector_flagging_guids)];
    return uniqueGuids
      .map((guid) => {
        const matchedColor = assignColorList?.filter((item) => item.is_deleted !== 1).find((item) => item.guid === guid);
        return matchedColor ? matchedColor.color_code : null;
      })
      ?.filter((item) => item !== null);
  };

  const handleLineSelection = (line, lineType) => {
    line.optional = 0;
    line.uuid = uuidv4();
    if (selectedLines.length === 0) {
      setSelectedLines([line]);
    } else {
      setSelectedLines((prev) => {
        let catIndex = prev.findIndex((item) => item.uuid === line.uuid);
        if (catIndex !== -1) {
          return prev.filter((prevCategory) => prevCategory.uuid !== line.uuid);
        } else return [...prev, line];
      });
    }
  };

  const categorylineRelation = (lineRelationData) => {
    categoryLineRelation(dispatch, {
      templateGuid: templateData?.template_guid,
      categoryUuid: relationcategoryData?.parent_uuid !== null ? relationcategoryData?.parent_uuid : relationcategoryData?.uuid,
      lineUuid: lineRelationData?.uuid,
      lineParentUuid: lineRelationData?.parent_uuid === null || lineRelationData?.parent_uuid === undefined ? lineRelationData?.uuid : lineRelationData?.parent_uuid,
    });
  };

  useEffect(() => {
    if (storeData.inspectionData) {
      const tempData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId);
      setTemplateData({
        ...tempData,
        bucket_url: storeData?.inspectionData?.data?.bucket_url,
      });
      setCategoryData(storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.categories?.find((category) => category.id === categoryId));
      setPdfCategoryData(storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.pdf_categories?.find((category) => category.id === categoryId));
    }
  }, [storeData.inspectionData]);

  useEffect(() => {
    if (storeData?.addInpUpdated === true) {
      setReRender((prev) => !prev);
    }
  }, [storeData?.addInpUpdated]);
  useEffect(() => {
    const tempData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId);
    setTemplateData({
      ...tempData,
      bucket_url: storeData?.inspectionData?.data?.bucket_url,
    });
    setCategoryData(storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.categories?.find((category) => category.id === categoryId));
    setPdfCategoryData(storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.pdf_categories?.find((category) => category.id === categoryId));
    dispatch(activateLines(""));
    setExpand(true);
  }, [location?.state?.template_id, location?.state?.category_id]);

  useEffect(() => {
    if (storeData.updatedInspectionData && storeData.updatedInspectionData !== "") {
      // toast.success(storeData?.updatedInspectionData?.message)
      dispatch(clearUpdatedInspectionData());
    }
  }, [storeData.updatedInspectionData]);

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData.inspectionData?.data);
    }
  }, [storeData.inspectionData]);

  //  useEffect(() => {
  //   if(storeData?.addInpUpdated === true) {
  //     window.location.reload(false);
  //   }
  //  }, [storeData?.addInpUpdated])

  useEffect(() => {
    setIntroText(categoryData !== null && categoryData?.introductory_text !== undefined && categoryData?.introductory_text !== null ? categoryData?.introductory_text : "");
  }, [categoryData, location?.state?.category_id]);

  // calling initiate-web-rw api after media update
  // useEffect(() => {
  //   if(storeData?.mediaUpdate) {
  //     let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem('AC'));
  //     let guid = sessionStorage.getItem('guid')
  //     let sessionUserId = Base64.decode(sessionStorage.getItem('UI'));
  //     dispatch(getInspectionData({guid:guid,associate_company:sessionAssociateCompanyKey,user_id:sessionUserId}))
  //   }
  // }, [storeData?.mediaUpdate])

  useEffect(() => {
    if (storeData?.loader) setLoader(true);
    else {
      setLoader(false);
      setLoader2(false);
    }
  }, [storeData?.loader]);

  // useEffect(() => {
  //   getDefaultLines(dispatch,
  //   {templateGuid:storeData.inspectionData?.data?.inspectiontemplates?.find(template=>template.template_id === templateId)?.template_guid,
  //   parentUuid:storeData?.inspectionData?.data?.inspectiontemplates?.find(template=>template.template_id === templateId)?.categories?.find(category=>category.id === categoryId)?.parent_uuid})
  // }, [location?.state?.category_id])

  // optional and standard categories
  useEffect(() => {
    if (storeData.defaultLineData) {
      setNewLineList(storeData.defaultLineData?.length !== 0 ? storeData.defaultLineData?.data : []);
    }
  }, [storeData.defaultLineData]);

  //  reorder
  const reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    setLoader(true);

    const reorderedItems = reorder(catoutput, result.source.index, result.destination.index);

    reorderedItems.forEach((x, i) => {
      x.order = i + 1;
      return x;
    });
    setCatOutput(reorderedItems);
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == activeEditLine.uuid);
    reorderedItems.map(async (item) => {
      item.updated_at = await GetUTCTime();
    });
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines = reorderedItems;
    let filteredData = prevData;
    let filteredTemplateObject = prevData?.inspectiontemplates[templateIndex];
    let filteredCategoryObject = filteredTemplateObject?.categories[categoryIndex];
    filteredTemplateObject.categories = [filteredCategoryObject];
    filteredData.inspectiontemplates = [filteredTemplateObject];
    UpdateTemplateDataSpecific(dispatch, prevData, filteredData);
    setReorderLineToastMessage(true);
  };

  // add media
  async function convertBase64ToFile(base64String, filename, file, formData, fileType, currentMediaLength, orderVal, e, videoCount, selectedFiles, lineUuid, categoryuuid, metadata) {
    setLoaderText(`uploading video ${videoUploadCount + 1} of ${videoCount}`);
    const arr = base64String[0].split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    let thumbVideoFile = new File([u8arr], filename, { type: mime });
    let thumbFile;
    const { imgWidth, imgHeight } = await getImageDimensions(thumbVideoFile);
    const maxWidth = 320;
    const maxHeight = 320;
    const quality = 1;
    let newWidth, newHeight;

    if (imgWidth > imgHeight) {
      // Landscape orientation
      newHeight = maxHeight;
      newWidth = (maxHeight / imgHeight) * imgWidth;
    } else {
      // Portrait orientation
      newWidth = maxWidth;
      newHeight = (maxWidth / imgWidth) * imgHeight;
    }
    let thumbnailImage = await resizeImage(thumbVideoFile, newWidth, newHeight, quality);
    thumbFile = new File([thumbnailImage], `${thumbnailImage.name}`, {
      type: "image/jpeg",
    });
    let imgObj = {
      id: uuidv4(),
      url: null,
      name: file.name,
      size: file.size,
      type: file.type,
      edited: false,
      newUrl: null,
      online: "online",
      filetype: fileType,
      localUrl: null,
      OriginUrl: null,
      editCount: 0,
      created_at: moment.utc(metadata).format("YYYY-MM-DD HH:mm:ss"),
      onlineUrl: null,
      orginName: null,
      thumbnail: null,
      is_deleted: 0,
      order: currentMediaLength + orderVal,
      updated_at: await GetUTCTime(),
      description: null,
      OriginlocalUrl: null,
      isOriginUploaded: true,
    };
    let img_obj_string = JSON.stringify(imgObj);
    let params = {};
    params = {
      video: file,
      inspection_guid: sessionStorage.getItem("guid"),
      template_id: templateId,
      video_thumbnail: thumbFile,
      category_id: categoryuuid,
      line_id: lineUuid,
      img_object: img_obj_string,
      img_type: "details",
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));

    // sync API call
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem("CK");
    let sessionSecretKey = sessionStorage.getItem("SK");
    try {
      const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
          "api-key": `${Base64.decode(sessionAPIKey)}`,
          platform: "web",
        },
      });
      const result = response.data;
      responseCount++;
      successCount++;
      videoUploadCount++;
    } catch (error) {
      console.error(`Error posting data for user:`, error);
      toast.error(error?.message);
      responseCount++;
      errorCount++;
      videoUploadCount++;
    }
    if (responseCount === selectedFiles.length) {
      if (successCount > 0)
        toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
          duration: 4000,
        });
      if (errorCount > 0)
        toast.error(
          `${errorCount - sizeErrorCount > 0
            ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
            : ""
          } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
          {
            duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
          }
        );
      callInitiateAPI();
      setDragActive(false);
      responseCount = 0;
      videoUploadCount = 0;
      imageuploadCount = 0;
      errorCount = 0;
      sizeErrorCount = 0;
      successCount = 0;
    }
  }

  const callInitiateAPI = () => {
    setLoader2(false);
    setLoaderText("");
    let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem("AC"));
    let guid = sessionStorage.getItem("guid");
    let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
    dispatch(
      getInspectionData({
        guid: guid,
        associate_company: sessionAssociateCompanyKey,
        user_id: sessionUserId,
      })
    );
    setLoader(false);
  };

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const getMediaData = (line) => {
    let medData = line?.line_input_controls?.find((data) => data.type === "Media")?.data;
    setLineMediaData(medData);
  };

  const outerHandleAddMedia = async (e, type, lineUuid, line, categoryuuid) => {
    getMediaData(line);
    let imageCount = 0;
    let videoCount = 0;
    let currentMediaLength = lineMediaData?.length;
    let fileSizeStatus = [];
    let formatStatus = [];
    let selectedFiles;
    if (type == "drag") {
      selectedFiles = e;
    } else {
      selectedFiles = e.target.files;
    }
    let metadata;
    setImage(selectedFiles);
    for (let i = 1; i <= selectedFiles?.length; i++) {
      let formData = new FormData();
      let file = selectedFiles[i - 1];

      const fileSize = file.size;
      const sizeInMB = Math.round(fileSize / (1024 * 1024));
      let fileTypeCheck = null;
      if (file?.type === "image/jpeg" || file?.type === "image/png" || file?.type === "image/jpg") {
        fileTypeCheck = "image";
      } else if (file?.type === "image/tiff" || file?.type === "image/bmp" || file?.type === "image/gif" || file?.type === "image/svg" || file?.type === "image/ico" || file?.type === "image/x-icon") {
        fileTypeCheck = "unsupported";
      } else {
        fileTypeCheck = "video";
      }
      if (fileTypeCheck === "image" || fileTypeCheck === "unsupported") imageCount++;
      if (fileTypeCheck === "video") videoCount++;
    }
    // new v1

    setLoader2(true);
    if (selectedFiles.length === 0) setLoader2(false);
    for (let i = 1; i <= selectedFiles.length; i++) {
      let formData = new FormData();
      let file = selectedFiles[i - 1];
      let fileType = null;
      if (file?.type === "image/jpeg" || file?.type === "image/png" || file?.type === "image/jpg") {
        fileType = "image";
      } else if (file?.type === "image/tiff" || file?.type === "image/bmp" || file?.type === "image/gif" || file?.type === "image/svg" || file?.type === "image/ico" || file?.type === "image/x-icon") {
        fileType = "unsupported";
      } else {
        fileType = "video";
      }
      if (fileType === "image") {
        try {
          metadata = await exifr.parse(file);
        } catch (error) {
          metadata = await await GetUTCTime();
        }
      }
      if (fileType === "video") {
        metadata = file?.lastModifiedDate;
      }
      const fileSize = file.size;
      const sizeInMB = Math.round(fileSize / (1024 * 1024));
      if (sizeInMB >= 1024) {
        // toast.error("File size too Big, please select a file less than 500 MB");
        errorCount++;
        responseCount++;
        sizeErrorCount++;
        if (responseCount === selectedFiles?.length) {
          if (successCount > 0)
            toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
              duration: 4000,
            });
          if (errorCount > 0)
            toast.error(
              `${errorCount - sizeErrorCount > 0
                ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
                : ""
              } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
              {
                duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
              }
            );
          callInitiateAPI();
          setDragActive(false);
          responseCount = 0;
          videoUploadCount = 0;
          imageuploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else if (!file.type.match(/(image\/(png|jpg|jpeg))|(video\/(mp4|mov|quicktime))/) || file?.name?.toLowerCase()?.endsWith(".jfif")) {
        errorCount++;
        responseCount++;
        if (responseCount === selectedFiles?.length) {
          if (successCount > 0)
            toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully.`, {
              duration: 4000,
            });
          if (errorCount > 0)
            toast.error(
              `${errorCount - sizeErrorCount > 0
                ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
                : ""
              } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
              {
                duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
              }
            );
          callInitiateAPI();
          setDragActive(false);
          responseCount = 0;
          videoUploadCount = 0;
          imageuploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else {
        if (fileType === "image") {
          setLoaderText(`compressing image ${imageuploadCount + 1} of ${imageCount}`);
          let compressedFile;
          let thumbImage;
          const { imgWidth, imgHeight } = await getImageDimensions(file);
          const maxWidth = 320;
          const maxHeight = 320;
          const quality = 0.8;
          let newWidth, newHeight;
          if (imgWidth > imgHeight) {
            // Landscape orientation
            newHeight = maxHeight;
            newWidth = (maxHeight / imgHeight) * imgWidth;
          } else {
            // Portrait orientation
            newWidth = maxWidth;
            newHeight = (maxWidth / imgWidth) * imgHeight;
          }
          let thumbnailImage = await resizeImage(file, newWidth, newHeight, quality);
          thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, {
            type: "image/jpeg",
          });
          if (file?.size > 512000) {
            // =====
            try {
              const compressedFilee = await compressImage(file);
              compressedFile = new File([compressedFilee], `${compressedFilee.name}`, { type: "image/jpeg" });
              setLoaderText(`uploading image ${imageuploadCount + 1} of ${imageCount}`);
              let imgObj = {
                id: uuidv4(),
                url: null,
                name: compressedFile.name,
                size: compressedFile.size,
                type: compressedFile.type,
                edited: false,
                newUrl: null,
                online: "online",
                filetype: fileType,
                localUrl: null,
                OriginUrl: null,
                editCount: 0,
                onlineUrl: null,
                orginName: null,
                thumbnail: null,
                is_deleted: 0,
                order: currentMediaLength + i,
                updated_at: await GetUTCTime(),
                created_at: moment.utc(metadata?.DateTimeOriginal).format("YYYY-MM-DD HH:mm:ss"),
                description: null,
                OriginlocalUrl: null,
                isOriginUploaded: true,
              };
              let img_obj_string = JSON.stringify(imgObj);
              let params = {};
              params = {
                image: compressedFile,
                video_thumbnail: thumbImage,
                inspection_guid: sessionStorage.getItem("guid"),
                template_id: templateId,
                category_id: categoryuuid,
                line_id: type === "non-drag" ? imageInputClickedLineUuid : lineUuid,
                img_object: img_obj_string,
                img_type: "details",
              };

              Object.keys(params).forEach((key) => formData.append(key, params[key]));

              // sync API call
              let baseUrl = await origin();
              let sessionAPIKey = sessionStorage.getItem("CK");
              let sessionSecretKey = sessionStorage.getItem("SK");
              try {
                const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
                    "api-key": `${Base64.decode(sessionAPIKey)}`,
                    platform: "web",
                  },
                });
                const result = response.data;
                responseCount++;
                successCount++;
                imageuploadCount++;
                // if(i === selectedFiles.length) callInitiateAPI()
              } catch (error) {
                responseCount++;
                errorCount++;
                imageuploadCount++;
                toast.error(error?.message);
                console.error(`Error posting data for user:`, error);
                // if(i === selectedFiles.length) callInitiateAPI()
              }

              if (responseCount === selectedFiles?.length) {
                if (successCount > 0)
                  toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
                    duration: 4000,
                  });
                if (errorCount > 0)
                  toast.error(
                    `${errorCount - sizeErrorCount > 0
                      ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
                      : ""
                    } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
                    {
                      duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
                    }
                  );
                callInitiateAPI();
                setDragActive(false);
                responseCount = 0;
                videoUploadCount = 0;
                imageuploadCount = 0;
                errorCount = 0;
                sizeErrorCount = 0;
                successCount = 0;
              }
            } catch (error) {
              console.error("Error compressing image:", error);
            }
            // =====
          }
          // compressedFile = await resizeFile(selectedFiles.item(i-1));
          else {
            compressedFile = file;
            setLoaderText(`uploading image ${imageuploadCount + 1} of ${imageCount}`);
            let imgObj = {
              id: uuidv4(),
              url: null,
              name: compressedFile.name,
              size: compressedFile.size,
              type: compressedFile.type,
              edited: false,
              newUrl: null,
              online: "online",
              filetype: fileType,
              localUrl: null,
              OriginUrl: null,
              editCount: 0,
              onlineUrl: null,
              orginName: null,
              thumbnail: null,
              created_at: moment.utc(metadata?.DateTimeOriginal).format("YYYY-MM-DD HH:mm:ss"),
              is_deleted: 0,
              order: currentMediaLength + i,
              updated_at: await GetUTCTime(),
              description: null,
              OriginlocalUrl: null,
              isOriginUploaded: true,
            };
            let img_obj_string = JSON.stringify(imgObj);
            let params = {};
            params = {
              image: compressedFile,
              video_thumbnail: thumbImage,
              inspection_guid: sessionStorage.getItem("guid"),
              template_id: templateId,
              category_id: categoryuuid,
              line_id: type === "non-drag" ? imageInputClickedLineUuid : lineUuid,
              img_object: img_obj_string,
              img_type: "details",
            };

            Object.keys(params).forEach((key) => formData.append(key, params[key]));

            // sync API call
            let baseUrl = await origin();
            let sessionAPIKey = sessionStorage.getItem("CK");
            let sessionSecretKey = sessionStorage.getItem("SK");
            try {
              const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
                  "api-key": `${Base64.decode(sessionAPIKey)}`,
                  platform: "web",
                },
              });
              const result = response.data;
              responseCount++;
              imageuploadCount++;
              successCount++;
              // if(i === selectedFiles.length) callInitiateAPI()
            } catch (error) {
              responseCount++;
              errorCount++;
              imageuploadCount++;
              toast.error(error?.message);
              console.error(`Error posting data for user:`, error);
              // if(i === selectedFiles.length) callInitiateAPI()
            }

            if (responseCount === selectedFiles?.length) {
              if (successCount > 0)
                toast.success(`${successCount} ${successCount === 1 ? "media item has" : "media items have"} been uploaded successfully. `, {
                  duration: 4000,
                });
              if (errorCount > 0)
                toast.error(
                  `${errorCount - sizeErrorCount > 0
                    ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"} not uploaded, the file format is not supported.`
                    : ""
                  } ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"} not uploaded because the media file is greater than 1024MB.` : ""}`,
                  {
                    duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
                  }
                );
              callInitiateAPI();
              setDragActive(false);
              responseCount = 0;
              videoUploadCount = 0;
              imageuploadCount = 0;
              errorCount = 0;
              sizeErrorCount = 0;
              successCount = 0;
            }
          }
          // sync api call
          // handleMediaUpload(formData)
        } else if (fileType === "video") {
          setLoader2(true);
          setLoaderText(`generating thumbnail for video ${videoUploadCount + 1} of ${videoCount}`);
          await generateVideoThumbnails(file, 10).then(async (thumbs) => {
            await convertBase64ToFile(
              thumbs,
              "name",
              file,
              formData,
              fileType,
              currentMediaLength,
              i,
              e,
              videoCount,
              selectedFiles,
              type === "non-drag" ? imageInputClickedLineUuid : lineUuid,
              categoryuuid,
              metadata
            );
          });
        }
      }
    }
  };

  // drag and drop
  const onDropFn = useCallback(
    (acceptedFiles, uuid, line, categoryuuid, is_inspection_details) => {
      if (is_inspection_details !== 1) {
        outerHandleAddMedia(acceptedFiles, "drag", uuid, line, categoryuuid);
      }
    },
    [outerHandleAddMedia]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDropFn,
    onDragOver: (event) => {
      setDragActive(true);
      setDragActiveText(event?.target?.id);
      setDragActiveuuid(event?.target?.innerText);
      // event.preventDefault();
    },
    onDragEnter: () => {
      // Your other code for onDragEnter
    },
    onDragLeave: () => {
      setDragActive(false);
      // Your other code for onDragLeave
    },
  });

  const assosiatedLineColor = (inspector_flagging_guids, assignColorList) => {
    if (!inspector_flagging_guids || inspector_flagging_guids?.length === 0) {
      return [];
    }
    return inspector_flagging_guids
      .map((guid) => {
        const matchedColor = assignColorList?.filter((item) => item.is_deleted !== 1).find((item) => item.guid === guid);
        return matchedColor ? matchedColor.color_code : null;
      })
      ?.filter((item) => item !== null);
  };

  const handleAssignColor = () => {
    setAssignColorIndicatorModal(true);
  };

  const colorForLine = (line) => {
    return (
      <>
        {line?.inspector_flagging_guids?.length === 1 && (
          <div style={{ position: "relative", width: "22px", height: "33px" }}>
            {assosiatedLineColor(line?.inspector_flagging_guids, assignColorList).map((color, index) => {
              const positions = [{ top: "10px", left: "0px" }];
              return <div key={index} style={{ position: "absolute", width: "15px", height: "15px", borderRadius: "50%", backgroundColor: color, ...positions[index] }}></div>;
            })}
          </div>
        )}
        {line?.inspector_flagging_guids?.length === 2 && (
          <div style={{ position: "relative", width: "22px", height: "33px" }}>
            {assosiatedLineColor(line?.inspector_flagging_guids, assignColorList).map((color, index) => {
              const positions = [
                { top: "0px", left: "0px" },
                { top: "20px", left: "0px" },
              ];
              return <div key={index} style={{ position: "absolute", width: "15px", height: "15px", borderRadius: "50%", backgroundColor: color, ...positions[index] }}></div>;
            })}
          </div>
        )}
        {line?.inspector_flagging_guids?.length >= 3 && (
          <div style={{ position: "relative", width: "22px", height: "33px" }}>
            {assosiatedLineColor(line?.inspector_flagging_guids, assignColorList)
              ?.slice(0, 3)
              ?.map((color, index) => {
                const positions = [
                  { top: "2px", left: "0px" },
                  { top: "20px", left: "0px" },
                  { top: "10px", left: "8px" },
                ];
                return <div key={index} style={{ position: "absolute", width: "15px", height: "15px", borderRadius: "50%", backgroundColor: color, ...positions[index] }}></div>;
              })}
            {line?.inspector_flagging_guids?.length > 3 && <div style={{ position: "absolute", top: "-8px", left: "16px", fontSize: "20px", fontWeight: "bold", color: "#3b3b3b" }}>+</div>}
          </div>
        )}
      </>
    );
  };

  const menuItemForNarrativeLines = () => {
    return (
      <Menu
        anchorEl={narrativeLineMenuAnchorEl}
        open={isNarrativeLineMenuOpen}
        onClose={handleNarrativeMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "rgba(0, 0, 0, 0.01) 0px 4px 4px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            padding: "8px 0",
            width: "240px",
          },
        }}
      >
        <MenuItem onClick={() => handleEditLine(assignColorIndicatorNarrativeLine)} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <MdOutlineModeEditOutline style={{ fontSize: "20px", color: "#555555" }} />
          <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Edit line name</span>
        </MenuItem>
        <Divider sx={{ margin: "8px 0" }} />
        <MenuItem style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px" }}>
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <MdOutlineLocalPrintshop style={{ fontSize: "20px", color: "#555555" }} />
            <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Mark line complete</span>
          </span>
          <Switch size="small" checked={assignColorIndicatorNarrativeLine?.is_completed} onChange={() => handleToggleCompleted(assignColorIndicatorNarrativeLine)} />
        </MenuItem>
        <MenuItem style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px" }}>
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <MdOutlineLocalPrintshop style={{ fontSize: "20px", color: "#555555" }} />
            <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Print in report</span>
          </span>
          <Switch size="small" checked={assignColorIndicatorNarrativeLine?.is_printable} onChange={() => handleTogglePrintable(assignColorIndicatorNarrativeLine)} />
        </MenuItem>
        <Divider sx={{ margin: "8px 0" }} />
        <MenuItem onClick={handleAssignColor} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <IoMdColorPalette style={{ fontSize: "20px", color: "#555555" }} />
          <span style={{ fontWeight: "400  !important", fontSize: "14px  !important", color: "#3b3b3b !important" }}>Assign color</span>
        </MenuItem>
        <Divider sx={{ margin: "8px 0" }} />
        <MenuItem
          onClick={() => handleDeleteLine(assignColorIndicatorNarrativeLine)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            fontWeight: "400  !important",
            fontSize: "14px !important",
          }}
        >
          <RiDeleteBin6Line style={{ fontSize: "20px", color: "#D53D3D" }} />
          <span>Delete line</span>
        </MenuItem>
      </Menu>
    );
  };

  useEffect(() => {
    if (storeData?.updatedInspectionData?.success === true && editLineNameToastMessage) {
      toast.success("The category has been updated successfully.");
      setEditLineNameToastMessage(false);
    }
    if (storeData?.updatedInspectionData?.success === true && deleteLineToastMessage) {
      toast.success("The category has been updated successfully.");
      setDeleteLineToastMessage(false);
    }
    if (storeData?.updatedInspectionData?.success === true && reorderLineToastMessage) {
      toast.success("The category has been updated successfully.");
      setReorderLineToastMessage(false);
    }
  }, [handleConfirmDeleteLine, handleConfirmEditLine, onDragEnd]);
  const handleEditIntro = (e) => {
    e.stopPropagation();

  }

  return (
    <motion.div className="h_100 w_bg" initial={{ opacity: 0.4 }} animate={{ opacity: 1 }}>
      {storeData?.scrolPage ? window.scrollTo(0, 0) : ""}

      <AddLineModal
        showInspLineModal={showInspLineModal}
        setShowInspLineModal={setShowInspLineModal}
        templateId={templateId}
        categoryId={categoryId}
        isInspDetails={categoryData?.is_inspection_details}
      />
      {/* <AddLineModal showLineModal={showLineModal} setShowLineModal={setShowLineModal} templateId={templateId} categoryId={categoryId} isInspDetails={categoryData?.is_inspection_details}/> */}
      <CreateNewLineModal
        showCreateNewLineModal={showCreateNewLineModal}
        setShowCreateNewLineModal={setShowCreateNewLineModal}
        setShowLineModal={setShowLineModal}
        templateId={templateId}
        categoryId={categoryId}
        isInspDetails={categoryData?.is_inspection_details}
      />
      <AddOptionalNdStandardLineModal
        showLineModal={showLineModal}
        setShowLineModal={setShowLineModal}
        templateId={templateId}
        categoryId={categoryId}
        isInspDetails={categoryData?.is_inspection_details}
        newLineList={newLineList}
        selectedLines={selectedLines}
        setSelectedLines={setSelectedLines}
        handleLineSelection={handleLineSelection}
        setShowCreateNewLineModal={setShowCreateNewLineModal}
      />
      {assignColorIndicatorModal && (
        <AssignColorIndicatorModal
          assignColorIndicatorModal={assignColorIndicatorModal}
          setAssignColorIndicatorModal={setAssignColorIndicatorModal}
          handleAssignColor={handleAssignColor}
          assignColorList={assignColorList}
          templateId={templateId}
          assignColorIndicatorCategory={categoryData}
          assignColorIndicatorLine={assignColorIndicatorNarrativeLine}
          setAssignColorIndicatorLine={setAssignColorIndicatorNarrativeLine}
          templateData={templateData}
          setLineMenuAnchorEl={setNarrativeLineMenuAnchorEl}
          action="lines"
        />
      )}
      <div style={{ boxSizing: "border-box", display: "box" }}>
        <div className="line-details-parent-div">
          <div className="line-details-child-div" style={{ borderRight: !matches && isPDF ? "1px solid #BEBEBE" : "none" }}>
            <Header
              title={!isPDF ? categoryData?.prompt : pdfCategoryData?.prompt}
              action={!isPDF ? "lines" : "pdfElement"}
              categoryData={categoryData}
              pdfCategoryData={pdfCategoryData}
              assosiatedCategoryColors={allAssociatedCategoryColors(categoryData, assignColorList)}
            />
            {matches && isPDF ? (
              <ReportLibrarySideBar
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                onDragtoggle={onDragtoggle}
                setOnDragToggle={setOnDragToggle}
                selectedMediaFiles={selectedMediaFiles}
                setSelectedMediaFiles={setSelectedMediaFiles}
              />
            ) : (
              ""
            )}
            <Preloader showPreloader={loader} />
            <PreloaderWithText showPreloader={loader2} loadText={loaderText} />
            <Grid className="line-details-container" sx={{ backgroundColor: !categoryData?.is_printable && "#F9F9F9 !important" }}>
              {/* search commented for now      */}
              {/* <Box className="header-search-wrapper">
                <Search className="header-search-bar">
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase placeholder="Search" inputProps={{ 'aria-label': 'search' }} />
                </Search>
              </Box> */}

              <Divider />
              {categoryData?.is_inspection_details !== 1 && !storeData?.editLine && !storeData?.reorderLines && !isPDF ? (
                <>
                  <Grid
                    container
                    className="intro_collhead_container"
                    onClick={() => {
                      setExpand(!expand);
                      setShowIntroTextModal(true);
                    }}
                  >
                    {/* <Grid container item xs={11}>
                      <Grid item xs={11}> */}
                    <div style={{ display: "block" }}>
                      <Typography className="sub_heading" sx={{ fontWeight: "600 !important", fontSize: "14px !important" }}>
                        {templateData?.is_texas === 1 && categoryData?.is_inspection_details === 2 ? "Additional Information" : "INTRODUCTORY TEXT"}
                      </Typography>
                    </div>
                    {/* </Grid> */}
                    {/* <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
                        {templateData?.is_texas === 1 &&
                          categoryData?.is_inspection_details === 2
                          ? null : <EditIcon onClick={(e) => handleEditIntro(e)} />}
                      </Grid> */}
                    {/* </Grid> */}
                    {
                      expand ? (
                        <KeyboardArrowUpIcon className="intro_drop_icon" onClick={() => setExpand(!expand)} />
                      ) : (
                        <KeyboardArrowDownIcon className="intro_drop_icon" onClick={() => setExpand(!expand)} />
                      )
                    }
                  </Grid >
                  <Collapse timeout={600} in={expand}>
                    <AddIntroText
                      showIntroTextModal={showIntroTextModal}
                      setShowIntroTextModal={setShowIntroTextModal}
                      introText={introText}
                      setIntroText={setIntroText}
                      categoryData={categoryData}
                      templateId={templateId}
                      categoryId={categoryId}
                      templateData={templateData}
                      isNarrative={isNarrative}
                      expand={expand}
                    />
                  </Collapse>
                </>
              ) : (
                ""
              )}
              <Divider />

              {/* reorder */}
              {
                storeData?.reorderLines ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={String(Math.floor(Math.random() * 100))}>
                      {(provided) => (
                        <div ref={provided.innerRef}>
                          {catoutput?.map((category, index) => {
                            return (
                              <>
                                {category?.is_deleted == 0 && (
                                  <Draggable
                                    draggableId={String(category.uuid)}
                                    // isDragDisabled ={actives && items.line_id === ids}
                                    key={category.uuid}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div key={index} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                                        <div className={`progress__section__category ${index === 0 ? "border-top" : ""}`} key={category.uuid}>
                                          <div className="edit_category pointer" id={`reorder_line_${index}`} style={{ marginRight: "15px" }}>
                                            <Typography>{category.prompt !== null ? category.prompt : "Untitled"}</Typography>
                                            <Typography className="edit_container_icons">
                                              <span>
                                                <ReorderIcon />
                                              </span>
                                            </Typography>
                                            {/* <BorderLinearProgress variant="determinate" value={0} /> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )}
                              </>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <>
                    {" "}
                    {storeData?.editLine ? (
                      categoryData?.lines?.map((line, index) =>
                        line.is_deleted !== 1 ? (
                          // &&line.optional !==1
                          <div className={`progress__section__category ${index === 0 && "border-top"}`} key={line.uuid}>
                            <div className="edit_category pointer">
                              <Typography> {line.prompt !== null ? line.prompt : "Untitled"}</Typography>
                              <Typography className="edit_container_icons">
                                <span onClick={() => handleEditLine(line)} id={`edit_line_${index}`}>
                                  <EditIcon className="edit__icon" />
                                </span>
                                <span onClick={() => handleDeleteLine(line)} id={`delete_line_${index}`}>
                                  <RemoveCircleOutlineIcon className="delete__icon" />
                                </span>
                              </Typography>
                              {/* <BorderLinearProgress variant="determinate" value={0} /> */}
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )
                    ) : !isPDF ? (
                      categoryData?.lines?.map((line, line_index) =>
                        line.is_deleted !== 1 && !line?.showLineEmpty ? (
                          // && line.optional !==1
                          <div
                            className={`line__content ${duplicateLineHighlighted && duplicateLineIdx === line_index && !line?.showLineEmpty ? "highlight-blue" : ""}`}
                            id={line.uuid}
                            key={line.uuid}
                            style={{
                              border: dragActive && (dragActiveText == line.uuid || dragActiveuuid == line.prompt) && "2px dashed #4282e2",
                              backgroundColor: !line?.is_printable ? "#F9F9F9" : "transparent",
                            }}
                            {...getRootProps({
                              onDrop: (event) => onDropFn(event.dataTransfer.files, line.uuid, line, categoryData.uuid, categoryData?.is_inspection_details),
                            })}
                            onClick={(e) => e?.stopPropagation()}
                          >
                            <div className="linecontent_title">
                              <>
                                <Typography
                                  className={
                                    categoryData?.is_inspection_details !== 1
                                      ? checkLineComplete(line)
                                        ? `line__content__text`
                                        : `line__content__text line__incomplete`
                                      : checkInspecLineComplete(line)
                                        ? `line__content__text`
                                        : `line__content__text line__incomplete`
                                  }
                                  id={`line_${line_index + 1}`}
                                  onClick={() => {
                                    if (categoryData?.is_inspection_details !== 1) {
                                      navigate(
                                        `/inspection/${categoryData.prompt !== null && categoryData.prompt !== "" ? encodeURIComponent(categoryData.prompt) : "Untitled"}/${line.prompt !== null && line.prompt !== "" ? encodeURIComponent(line.prompt) : "Untitled"
                                        }`,
                                        {
                                          state: {
                                            line,
                                            category_name: categoryData.prompt,
                                            template_id: templateData.template_id,
                                            category_id: categoryData.id,
                                            category_uuid: categoryData.uuid,
                                            line_uuid: line.uuid,
                                            isNarrative,
                                          },
                                        }
                                      );
                                      categorylineRelation(line);
                                      activateLine(line.uuid);
                                    }
                                  }}
                                >
                                  {categoryData?.is_inspection_details !== 1 ? (
                                    (checkLineComplete(line) || line?.is_completed) ? (
                                      <>
                                        <DoneIcon className={`done_icon visible`} />
                                        {line.prompt !== null ? line.prompt : "Untitled"}
                                        {!line?.is_printable && <TbPrinterOff className="print_off" />}
                                      </>
                                    ) : (line?.inspector_flagging_guids || [])?.length !== 0 ? (
                                      <>
                                        {colorForLine(line, assignColorList)}
                                        {line.prompt !== null ? line.prompt : "Untitled"}
                                        {!line?.is_printable && <TbPrinterOff className="print_off" />}
                                      </>
                                    ) : (
                                      <>
                                        <DoneIcon className={`done_icon hidden`} /> {line.prompt !== null ? line.prompt : "Untitled"}
                                        {!line?.is_printable && <TbPrinterOff className="print_off" />}
                                      </>
                                    )
                                  ) : checkInspecLineComplete(line) ? (
                                    <>
                                      <DoneIcon className={`done_icon visible`} /> {line.prompt !== null ? line.prompt : "Untitled"}
                                    </>
                                  ) : (
                                    <>
                                      <DoneIcon className={`done_icon hidden`} /> {line.prompt !== null ? line.prompt : "Untitled"}
                                    </>
                                  )}
                                </Typography>
                              </>
                              {categoryData?.is_inspection_details !== 1 && (
                                <span className="no_des_flex">
                                  <p
                                    style={{
                                      display: checkDescriptionDisabled(line) || isNarrative ? "flex" : "none",
                                    }}
                                    className={`line_content_comment btn_height ${matches ? "responsive" : ""}`}
                                    id={`add_comment_btn_${line_index + 1}`}
                                    onClick={(e) => {
                                      navigate(
                                        `/inspection/${categoryData.prompt !== null && categoryData.prompt !== "" ? encodeURIComponent(categoryData.prompt) : "Untitled"}/${line.prompt !== null && line.prompt !== "" ? encodeURIComponent(line.prompt) : "Untitled"
                                        }`,
                                        {
                                          state: {
                                            line,
                                            category_name: categoryData.prompt,
                                            template_id: templateData.template_id,
                                            category_id: categoryData.id,
                                            category_uuid: categoryData.uuid,
                                            line_uuid: line.uuid,
                                            target: "comment",
                                            add_comment_from_line: true,
                                            isNarrative,
                                          },
                                        }
                                      );
                                      activateLine(line.uuid);
                                      if (isNarrative) dispatch(setShowCommentLibraryModal(true));
                                      e.stopPropagation();
                                    }}
                                  >
                                    <MessageIcon />
                                    {!matches && <span>Add comment</span>}{" "}
                                  </p>
                                  {isNarrative && (
                                    <div className="line__narrative_moreicon">
                                      <MoreVertIcon sx={{ cursor: "pointer", color: "#9B9B9B", zIndex: 10 }} onClick={(event) => handleNarrativeMoreIconClick(event, line, line_index)} />
                                    </div>
                                  )}
                                  {menuItemForNarrativeLines()}
                                  {!isNarrative && line?.line_input_controls?.find((data) => data?.type === "Media")?.is_disabled != 1 ? (
                                    <p
                                      style={{
                                        display: checkDescriptionDisabled(line) || isNarrative ? "flex" : "none",
                                      }}
                                      className={`line_content_photo btn_height ${matches ? "responsive" : ""}`}
                                      onClick={(e) => {
                                        fileInputLine.current.click();
                                        setImageClickedLineUuid(line.uuid);
                                        e.stopPropagation();
                                      }}
                                      id={`add_line_image_btn_${line_index + 1}`}
                                    >
                                      <input
                                        ref={fileInputLine}
                                        type="file"
                                        accept="image/*,video/*"
                                        onChange={(e) => outerHandleAddMedia(e, "non-drag", line.uuid, line, categoryData?.uuid)}
                                        style={{ display: "none" }}
                                        multiple
                                      />
                                      <CameraAltIcon /> {!matches && <span>Add media</span>}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <KeyboardArrowRightIcon
                                    className="lineArrow_icon"
                                    onClick={(e) => {
                                      navigate(
                                        `/inspection/${categoryData.prompt !== null && categoryData.prompt !== "" ? encodeURIComponent(categoryData.prompt) : "Untitled"}/${line.prompt !== null && line?.prompt !== "" ? encodeURIComponent(line.prompt) : "Untitled"
                                        }`,
                                        {
                                          state: {
                                            line,
                                            category_name: categoryData.prompt,
                                            template_id: templateData.template_id,
                                            category_id: categoryData.id,
                                            category_uuid: categoryData.uuid,
                                            line_uuid: line.uuid,
                                            isNarrative,
                                          },
                                        }
                                      );
                                      categorylineRelation(line);
                                      activateLine(line.uuid);
                                      e.stopPropagation();
                                    }}
                                    id={`line_arrow_${line_index + 1}`}
                                  />
                                </span>
                              )}
                            </div>

                            {/* description */}
                            {categoryData?.is_inspection_details === 1 ? (
                              <InspectionDetailsLineDescription
                                line={line}
                                line_index={line_index}
                                templateId={templateId}
                                categoryId={categoryId}
                                categoryData={categoryData}
                                template_id={templateData?.template_id}
                                category_id={categoryData?.id}
                                category_uuid={categoryData.uuid}
                                lineUuid={line?.uuid}
                              />
                            ) : (
                              <LineDescription
                                line={line}
                                templateId={templateId}
                                categoryId={categoryId}
                                categoryData={categoryData}
                                template_id={templateData?.template_id}
                                category_id={categoryData?.id}
                                category_uuid={categoryData.uuid}
                                lineUuid={line?.uuid}
                                setReRender={setReRender}
                                render={render}
                                loader={loader}
                                setLoader={setLoader}
                                setLoader2={setLoader2}
                                setLoaderText={setLoaderText}
                                dragAndDropFile={dragAndDropFile}
                                lineIndex={line_index}
                                isNarrative={isNarrative}
                                // setEditLineNameToastMessage={setEditLineNameToastMessage}
                                inspectionResponse={inspectionResponse}
                                setDuplicateLineHighlighted={setDuplicateLineHighlighted}
                                setDuplicateLineIdx={setDuplicateLineIdx}
                              />
                            )}
                            {/* line media details */}
                            {RatingCheck(line, isNarrative).length > 0 ||
                              mediaCheck(line, isNarrative).length > 0 ||
                              videoCheck(line, isNarrative).length > 0 ||
                              narrativeCommentCount(line, isNarrative).length > 0 ||
                              narrativeRatingCheck(line, isNarrative).length > 0 ? (
                              line?.line_input_controls?.find((data) => data?.type === "Media")?.is_disabled != 1 ? (
                                <div className="linecontent_details">
                                  {ratingData(RatingCheck(line, isNarrative))}
                                  {RatingCheck(line, isNarrative).length > 0 && (mediaCheck(line, isNarrative).length > 0 || videoCheck(line, isNarrative).length > 0) ? (
                                    <Divider orientation="vertical" flexItem />
                                  ) : null}
                                  {/* narrativeRating */}
                                  {isNarrative && narrativeRatingCheck(line, isNarrative)?.length > 0 ? narrativeRatingData(narrativeRatingCheck(line, isNarrative)) : null}
                                  {isNarrative && narrativeRatingCheck(line, isNarrative).length > 0 ? <Divider orientation="vertical" flexItem /> : null}
                                  {/* narrativeCommentcount */}
                                  {isNarrative && narrativeCommentCount(line, isNarrative)?.length > 0 ? commentData(narrativeCommentCount(line, isNarrative)) : null}
                                  {mediaCheck(line, isNarrative).length > 0 || videoCheck(line, isNarrative).length > 0 ? <Divider orientation="vertical" flexItem /> : null}
                                  {mediaCheck(line, isNarrative).length > 0 ? imageData(mediaCheck(line, isNarrative)) : null}
                                  {videoCheck(line, isNarrative).length > 0 ? videoData(videoCheck(line, isNarrative)) : null}
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      )
                    ) : (
                      <PdfCategories
                        onDragtoggle={onDragtoggle}
                        setOnDragToggle={setOnDragToggle}
                        selectedMediaFiles={selectedMediaFiles}
                        setSelectedMediaFiles={setSelectedMediaFiles}
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                        pdfCategoryData={pdfCategoryData}
                        templateId={templateId}
                        templateGuid={templateGuid}
                        templateData={templateData}
                      />
                    )}
                  </>
                )
              }
              {
                !storeData?.editLine && !storeData?.reorderLines && !isPDF ? (
                  !(templateData?.is_texas === 1 && categoryData?.is_inspection_details === 2) ? (
                    <div className="add__category" onClick={() => handleAddLine(categoryData?.is_inspection_details, categoryData?.parent_uuid)}>
                      <AddItemButton text="Line" width="100%" id="add_new_line" />
                    </div>
                  ) : null
                ) : null
              }
            </Grid >
            {/* delete alert dialog */}
            < DeleteConfirmationDialog
              open={deleteLineModal}
              handleClose={handleCloseDeleteAlert}
              title={"Delete line"}
              contentText={`Are you sure you want to delete the line ${activeDeleteLine?.prompt}?`}
              cancelBtnTitle={"Cancel"}
              cancelBtnAction={handleCloseDeleteAlert}
              submitBtnTitle={"Delete"}
              submitBtnAction={handleConfirmDeleteLine}
            />
            {/* delete dialog end */}

            {/* edit alert dialog */}
            <Modal
              open={showEditLineModal}
              onClose={handleCloseEditAlert}
              BackdropProps={{ style: { opacity: "60%" } }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="mark-as-complete-modal-main"
            >
              <Box className="mark-as-complete-modal" sx={{ minHeight: "201px !important", top: "40% !important" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <CloseIcon
                      id="close_edit_modal"
                      sx={{
                        float: "right",
                        cursor: "pointer",
                        color: "#A5A5A5",
                        mt: 1,
                        mr: 1,
                      }}
                      onClick={handleCloseEditAlert}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "left",
                      marginBottom: "20px",
                      marginLeft: "15px",
                    }}
                  >
                    <p className="modal-heading">Edit line name </p>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="standard" className="forms-control" style={{ paddingLeft: "15px" }}>
                      <label className="input-form-label" htmlFor="CategoryName">
                        Line Name
                      </label>
                      <TextField
                        id="line_name"
                        className="input-textfield"
                        size="small"
                        variant="outlined"
                        placeholder="Line name"
                        type="text"
                        value={editLineName}
                        onChange={(e) => setEditLineName(e.target.value)}
                        style={{ width: "97%" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      mt: "14px",
                      mb: "30px",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <FilledButton width="95%" text="Save" onClick={() => handleConfirmEditLine(editLineName)} id="edit_save_button" />
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </div >
          {!matches && isPDF ? (
            <div style={{ minHeight: "100vh", padding: "13px 3px 10px 3px" }}>
              <div>
                <ReportLibrarySideBar
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  onDragtoggle={onDragtoggle}
                  setOnDragToggle={setOnDragToggle}
                  selectedMediaFiles={selectedMediaFiles}
                  setSelectedMediaFiles={setSelectedMediaFiles}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div >
      </div >
      {/* edit dialog end */}
    </motion.div >
  );
};
export default Lines;
