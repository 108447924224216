import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function DeleteMediaDialog(props) {
  const { open, handleClose, deleteMedia, cancelBtnAction, handleDeleteRMLMedia } = props;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={true} sx={{ "& .MuiPaper-root": { borderRadius: "8px" } }}>
      <DialogTitle sx={{ display: "flex", justifyContent: "flex-end", pb: 0 }}>
        <IconButton
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <CloseIcon className="close_btn" onClick={handleClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important", color: "#252525 !important" }}>Delete media?</Typography>
          </Grid>
          {/* <Grid item xs={12} display={"flex"} mb={2} justifyContent={"center"} sx={{wordBreak: "break-all", textAlign: "center"}}>
            <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important", color: "#252525 !important" }}>{`'${deleteMedia?.name}'`}?</Typography>
          </Grid> */}
          <DialogContentText sx={{ fontSize: "14px !important", fontWeight: "400 !important", color: "#252525 !important", mt: 3 }}>
            <div>Are you sure you want to delete this media item from the report?</div>
            <div style={{ marginLeft: "20%" }}>This action cannot be undone.</div>
          </DialogContentText>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container display={"flex"} mb={1.5} justifyContent={"center"} gap={2}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button
              className="delete_confirm_btn"
              variant="contained"
              onClick={() => handleDeleteRMLMedia()}
              sx={{
                "&:hover": {
                  backgroundColor: "#E30303 !important",
                },
              }}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button variant="text" className="delete_cancel_btn" disableRipple onClick={cancelBtnAction}>
              <Typography className="custom-underline">Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteMediaDialog;
