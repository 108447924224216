const config_settings = {
  "localhost:3000": {
    // SERVER_URL: "https://pr-327-rwapi.palmtechpr.com/api/",
    SERVER_URL: "https://dev-rw-api.yourinspection.app/public/index.php/api/",
    IR_URL: "https://develop.myinspection.report/",
    PORTAL_URL: "https://develop.palmtech.dev/",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
  },
  "localhost:3001": {
    SERVER_URL: "https://dev-rw-api.yourinspection.app/public/index.php/api/",
    IR_URL: "https://develop.myinspection.report/",
    PORTAL_URL: "https://develop.palmtech.dev/",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
  },
  "localhost:3002": {
    SERVER_URL: "https://dev-rw-api.yourinspection.app/public/index.php/api/",
    IR_URL: "https://develop.myinspection.report/",
    PORTAL_URL: "https://develop.palmtech.dev/",
    PORTAL_SERVER_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
  },
  "develop.d1b2u24rehxcop.amplifyapp.com": {
    SERVER_URL: "https://dev-rw-api.yourinspection.app/public/index.php/api/",
    IR_URL: "https://develop.myinspection.report/",
    PORTAL_URL: "https://develop.palmtech.dev/",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
  },
  "develop.report-writer.yourinspection.app": {
    SERVER_URL: "https://dev-rw-api.yourinspection.app/public/index.php/api/",
    IR_URL: "https://develop.myinspection.report/",
    PORTAL_URL: "https://develop.palmtech.dev/",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
  },
  "integration.report-writer.yourinspection.app": {
    SERVER_URL: "https://int-rw-api.yourinspection.app/public/index.php/api/",
    IR_URL: "https://integration.myinspection.report/",
    PORTAL_URL: "https://integration.palmtech.dev/",
    PORTAL_SERVER_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
  },
  "staging.report-writer.yourinspection.app": {
    SERVER_URL: "https://stg-rw-api.yourinspection.app/public/index.php/api/",
    IR_URL: "https://staging.myinspection.report/",
    PORTAL_URL: "https://staging.palmtech.com/",
    PORTAL_SERVER_URL: "https://stg-portal-api.palmtech.com/public/index.php/api",
  },
  "report-writer.yourinspection.app": {
    SERVER_URL: "https://prod-rw-api.yourinspection.app/public/index.php/api/",
    IR_URL: "https://myinspection.report/",
    PORTAL_URL: "https://portal.palmtech.com/",
    PORTAL_SERVER_URL: "https://prod-portal-api.palmtech.com/public/index.php/api",
  },
  "$PRID-wrw.palmtechpr.com": {
    /// Use this code of block for PR Tetsing environment ////
    // SERVER_URL: "https://int-rw-api.yourinspection.app/public/index.php/api/",
    SERVER_URL: "https://pr-327-rwapi.palmtechpr.com/api/",
    IR_URL: "https://integration.myinspection.report/",
    PORTAL_URL: "https://integration.palmtech.dev/",
    PORTAL_SERVER_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
  },
};

export default config_settings;
