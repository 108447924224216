import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function CopySummaryModal(props) {
    const { open, handleClose, dialogTitle, handleSubmit, submitButtonTitle, dialogContentText, dialogRef } = props;

    const handleCancel = () => {
        handleClose(false);
    };

    return (
        <Dialog ref={dialogRef || null} open={open} onClose={handleCancel} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle sx={{ display: "flex", justifyContent: "center", pb: 0 }}>
                <DialogTitle className="category-dialog-title">{dialogTitle}</DialogTitle>
            </DialogTitle>
            <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} display={"flex"} my={2} justifyContent={"center"} alignItems={"center"} alignContent={"center"}>
                        <DialogContentText sx={{ fontSize: "14px !important", fontWeight: "400 !important", color: "#252525 !important" }}>
                            {dialogContentText || (
                                <>
                                    The dropdown in your copied text won’t be included if you paste it normally. To include the dropdown, use the <strong>Copy</strong> button instead.
                                </>
                            )}
                        </DialogContentText>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container display={"flex"} mb={1.5} justifyContent={"center"} gap={2}>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Button
                            className="delete_confirm_btn"
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{
                                backgroundColor: "#0F75BC !important",
                                width: "320px !important",
                                height: "36px !important",
                                "&:hover": { backgroundColor: "#0F75BC !important" },
                            }}>
                            {submitButtonTitle}
                        </Button>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Button variant="text" className="delete_cancel_btn" disableRipple onClick={() => handleCancel()}>
                            <Typography className="custom-underline">Cancel</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default CopySummaryModal;