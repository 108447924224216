import {
  updateInspectionDataCall,
  updateInspectionDataCallForAddInp,
  updateInspectionTemplateDataOffline,
  uploadMediaCall,
  markReportCompleteCall,
  markReportCompleteISNCall,
  markReportCompleteISNSendReportCall,
  getDefaultCategoriesCall,
  getDefaultCategoriesDataCall,
  getMasterTemplatesCall,
  updateISNTemplatesCall,
  uploadAttachment,
  updateInspectionDataCallWithLoader,
  getDefaultLinesCall,
  getTemplateListCall,
  swapInspectionDetailsCall,
  getDeletedTemplateDataCall,
  duplicateLine,
  duplicateCategory,
  porchPdfGenerateCall,
  getCommentLibraryListCall,
  uploadBulkMediaCall,
  addSelectedCommentsFromClCall,
  updateClCommentStatusCall,
  addNewCommentNarrativeCall,
  getCommentLibraryTagListCall,
  getCommentLibrarySummaryTagListCall,
  updateDropDownValuesCall,
  updateInspectionMediaDataCall,
  assignInspectorFlagToCategory,
  editInspectorFlagList,
  assignInspectorFlagToLine,
  categoryLineRelationCall,
  introductoryTextMedia,
} from "../Redux/Actions/CommonActions";

export const UpdateTemplateData = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateInspectionTemplateDataOffline(data));
  await dispatch(updateInspectionDataCall(data));

  // let network = idx(commonReducer, (_) => _.networkCheckRes?.isNetworkStatus);

  // let params = {
  //   tblName: TableNames.InspectionReportWriter,
  //   guid: data.guid,
  //   data: JSON.stringify(data),
  //   type: SelectTypes.InspectionReportWriter,
  //   isEdited: network ? 0 : 1,
  // };

  // UpdateData(params);
  // await dispatch(dataLocalCheckRequest({ isDataLocal: true }));

  // if (isCategoryApiCall) {
  //   await dispatch(
  //     isCategoryWiseApiCallRequest({ isCategoryWiseApiCall: true })
  //   );
  // }
};
export const UpdateTemplateDataWithLoader = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateInspectionTemplateDataOffline(data));
  await dispatch(updateInspectionDataCallWithLoader(data));
};

export const UpdateTemplateDataForAddInfo = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateInspectionTemplateDataOffline(data));
  await dispatch(updateInspectionDataCallForAddInp(data));
};

export const UpdateMedia = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  // await dispatch(updateInspectionTemplateDataOffline(data));
  await dispatch(uploadMediaCall(data));
};

export const UpdateBulkMedia = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  // await dispatch(updateInspectionTemplateDataOffline(data));
  await dispatch(uploadBulkMediaCall(data));
};

export const UpdateAttachment = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(uploadAttachment(data));
};

export const ApiCallDuplicateLine = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(duplicateLine(data));
};

export const ApiCallDuplicateCategory = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(duplicateCategory(data));
};
export const ApiCallForAssignInspectorToCategory = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(assignInspectorFlagToCategory(data));
};
export const ApiCallForAssignInspectorToLine = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(assignInspectorFlagToLine(data));
};
export const ApiCallForEditInspectorFlagList = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(editInspectorFlagList(data));
};
export const apiCallintroductoryTextMedia = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(introductoryTextMedia(data));
};

export const markReportComplete = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(markReportCompleteCall(data));
};
export const porchPdfGenerate = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(porchPdfGenerateCall(data));
};

export const swapInspectionTemplate = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(swapInspectionDetailsCall(data));
};

// get deleted template data
export const getDeletedTemplateData = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(getDeletedTemplateDataCall(data));
};

export const categoryLineRelation = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(categoryLineRelationCall(data));
};

// ISN mark as complete
export const markReportCompleteISN = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(markReportCompleteISNCall(data));
};
// ISN mark as complete
export const markReportCompleteISNSendReport = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(markReportCompleteISNSendReportCall(data));
};

// get default categories list
export const getDefaultCategories = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(getDefaultCategoriesCall(data));
};

// get default lines
export const getDefaultLines = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(getDefaultLinesCall(data));
};

export const UpdateTemplateDataSpecific = async (dispatch, data, filteredValue, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateInspectionTemplateDataOffline(data));
  await dispatch(updateInspectionDataCall(filteredValue));
  // required for future use
  // await dispatch(uploadBulkMediaCall(data));
};
export const UpdateTemplateMediaDataSpecific = async (dispatch, data, filteredValue, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateInspectionMediaDataCall(filteredValue));
};

export const UpdateTemplateDataSpecificWithLoader = async (dispatch, data, filteredValue, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateInspectionTemplateDataOffline(data));
  await dispatch(updateInspectionDataCallWithLoader(filteredValue));
};

// get default categories data get api
export const getDefaultCategoriesData = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(getDefaultCategoriesDataCall(data));
};
// get master templates list for ISN
export const getMasterTemplates = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(getMasterTemplatesCall(data));
};
// setup templates list for ISN
export const updateISNTemplates = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateISNTemplatesCall(data));
};

// get comment library list
export const getCommentLibraryList = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(getCommentLibraryListCall(data));
};
// Add comments to Cl
export const addSelectedCommentesFromCl = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(addSelectedCommentsFromClCall(data));
};
// Add new comments to Cl
export const addNewCommentNarrative = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(addNewCommentNarrativeCall(data));
};
// update cl comment check status
export const updateClCommentStatus = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateClCommentStatusCall(data));
};
// get comment library tag list
export const getCommentLibraryTagList = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(getCommentLibraryTagListCall(data));
};
// get comment library summary tag list
export const getCommentLibrarySummaryTagList = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(getCommentLibrarySummaryTagListCall(data));
};
// update dropdown values
export const updateDropDownValues = async (dispatch, data, commonReducer, isCategoryApiCall = false) => {
  await dispatch(updateDropDownValuesCall(data));
};
