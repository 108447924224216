import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import Header from "../../Components/Header/Header";
import "./report_library.scss";
import { Box, Button, Divider, FormControl, InputLabel, ListItemIcon, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ReportLibraryAllMedia from "./ReportLibraryAllMedia";
import ReportLibraryImage from "./ReportLibraryImage";
import ReportLibraryVideo from "./ReportLibraryVideo";
import { useSelector } from "react-redux";
import { apiCallintroductoryTextMedia, UpdateBulkMedia, UpdateTemplateData } from "../../Helpers/ApiCall";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/Check";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  UpdateReduxLocal,
  bulkUploadMediaLength,
  failBulkUploadMediaLength,
  failedBulkUploadMedia,
  getInspectionData,
  getInspectionDataWithOutLoader,
  isbulkUploading,
  successfulBulkUploadMediaLength,
} from "../../Redux/Actions/CommonActions";
import { Base64 } from "js-base64";
import { useEffect } from "react";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import { categoryMediaCheck, getImageDimensions, mediaCheck, resizeImage, videoCheck } from "../../Helpers/HelperFunctions";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Compressor from "compressorjs";
import { origin } from "../../Config/Api-constants";
import { useLocation } from "react-router-dom";
import DeleteRMLMedia from "../../Components/LineDetails/CommentDetails/DeleteRMLMedia";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import moment from "moment";
import exifr from "exifr";

const sortFilters = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Used",
    label: "Used",
  },
  {
    value: "Unused",
    label: "Unused",
  },
];

const filters = [
  {
    value: "Most used",
    label: "Most used",
  },
  {
    value: "Newest",
    label: "Newest",
  },
  {
    value: "Oldest",
    label: "Oldest",
  },
  {
    value: "File name",
    label: "File name",
  },
];

const theme = createTheme({
  typography: {
    body1: {
      fontSize: "12px",
    },
  },
  customMenuItem: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    "&.Mui-selected": {
      backgroundColor: "#e0e0e0",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
let cancelBulkUploading = false;

function ReportLibrary() {
  const [activeTab, setActiveTab] = useState(0);
  const [filterValue, setFilterValue] = useState("All");
  const [selected, setSelected] = React.useState("Newest");
  const [image, setImage] = useState("");
  const [mediaList, setMediaList] = useState([]);
  const [failedMediaList, setFailedMediaList] = useState([]);
  const [hoveredDeletedMediaUuid, setHoveredDeletedMediaUuid] = useState("");
  const [openDeleteMediaModal, setOpenDeleteMediaModal] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState("");
  const storeData = useSelector((state) => state.CommonReducer);
  const location = useLocation();
  const isReportMediaLibrary = location.pathname === "/report_media_library";
  let fileInput = useRef(null);
  const dispatch = useDispatch();
  let responseCount = 0;
  let videoUploadCount = 0;
  let imageUploadCount = 0;
  let successCount = 0;
  let errorCount = 0;
  let sizeErrorCount = 0;
  let failedBulkUpload = [];
  let UploadedDuplicateMedia = storeData?.inspectionDataForBulkMedia?.data?.report_media_library_files || [];

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleErrorDoneButton = () => {
    dispatch(isbulkUploading(false));
    dispatch(failBulkUploadMediaLength(0));
    dispatch(bulkUploadMediaLength(0));
    dispatch(failedBulkUploadMedia([]));
    callInitiateAPI();
    let reportData = storeData?.inspectionDataForBulkMedia;
    reportData.data.report_media_library_files = UploadedDuplicateMedia?.filter((object) => object?.online_url != null);
    dispatch(UpdateReduxLocal(reportData));
  };
  const handleCancelBulkUploadErrorModal = () => {
    dispatch(bulkUploadMediaLength(0));
    dispatch(successfulBulkUploadMediaLength(0));
    callInitiateAPI();
  };

  const handleFilterChange = (e) => {
    setFilterValue(e?.target?.value);
  };
  const handleTimeFilterChange = (e) => {
    setSelected(e?.target?.value);
  };

  const handleCancelBulkUploadMedia = () => {
    cancelBulkUploading = true;
    dispatch(bulkUploadMediaLength(0));
    dispatch(isbulkUploading(false));
  };

  async function convertBase64ToFile(base64String, filename, file, formData, fileType, currentMediaLength, orderVal, e, type, UploadedDuplicateMedia, failedBulkUpload, metadata) {
    const arr = base64String[0].split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let uploadfile;
    if (type == "drag") {
      uploadfile = e;
    } else {
      uploadfile = e.target.files;
    }
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let thumbVideoFile = new File([u8arr], filename, { type: mime });
    let thumbFile;
    const { imgWidth, imgHeight } = await getImageDimensions(thumbVideoFile);
    const maxWidth = 320;
    const maxHeight = 320;
    const quality = 1;
    let newWidth, newHeight;

    if (imgWidth > imgHeight) {
      // Landscape orientation
      newHeight = maxHeight;
      newWidth = (maxHeight / imgHeight) * imgWidth;
    } else {
      // Portrait orientation
      newWidth = maxWidth;
      newHeight = (maxWidth / imgWidth) * imgHeight;
    }
    let thumbnailImage = await resizeImage(thumbVideoFile, newWidth, newHeight, quality);
    thumbFile = new File([thumbnailImage], `${thumbnailImage.name}`, { type: "image/jpeg" });
    let params = {};
    params = {
      fileToUpload: file,
      inspectionGuid: sessionStorage.getItem("guid"),
      created_at: moment.utc(metadata).format("YYYY-MM-DD HH:mm:ss"),
      thumbnail: thumbFile,
      fileGuid: file?.uuid,
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));

    // sync API call
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem("CK");
    let sessionSecretKey = sessionStorage.getItem("SK");
    try {
      const response = await axios.post(`${baseUrl}media-lib-file-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
          "api-key": `${Base64.decode(sessionAPIKey)}`,
          platform: "web",
        },
      });
      const result = response.data;
      const mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
      if (mediaIndex !== -1) {
        UploadedDuplicateMedia[mediaIndex].online_url = result?.data?.onlineUrl;
        UploadedDuplicateMedia[mediaIndex].thumbnail_url = result?.data?.thumbnail;
        UploadedDuplicateMedia[mediaIndex].name = file?.name;
      }
      let reportData = storeData?.inspectionDataForBulkMedia;
      reportData.data.report_media_library_files = UploadedDuplicateMedia;
      dispatch(UpdateReduxLocal(reportData));
      responseCount++;
      videoUploadCount++;
      successCount++;
      dispatch(successfulBulkUploadMediaLength(successCount));
    } catch (error) {
      setFailedMediaList((prev) => [...prev, file]);
      failedBulkUpload.push(file);
      dispatch(failedBulkUploadMedia(failedBulkUpload));
      const mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
      if (mediaIndex !== -1) {
        UploadedDuplicateMedia[mediaIndex].is_media_uploading = false;
      }
      let reportData = storeData?.inspectionDataForBulkMedia;
      reportData.data.report_media_library_files = UploadedDuplicateMedia;
      dispatch(UpdateReduxLocal(reportData));
      responseCount++;
      videoUploadCount++;
      errorCount++;
      toast.error(error?.message);
    }
    if (responseCount === uploadfile?.length) {
      responseCount = 0;
      videoUploadCount = 0;
      imageUploadCount = 0;
      errorCount = 0;
      sizeErrorCount = 0;
      successCount = 0;
    }
    dispatch(isbulkUploading(false));
  }

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const handleAddMedia = async (e, type) => {
    dispatch(successfulBulkUploadMediaLength(0));
    dispatch(failBulkUploadMediaLength(0));
    dispatch(bulkUploadMediaLength(0));
    let currentMediaLength = 0;
    let uploadfile;
    let metadata;
    if (type == "retry") {
      uploadfile = e;
    } else uploadfile = e?.target?.files;
    dispatch(bulkUploadMediaLength(uploadfile?.length));
    setImage(uploadfile);
    for (let i = 1; i <= uploadfile.length; i++) {
      dispatch(isbulkUploading(true));
      if (cancelBulkUploading) {
        cancelBulkUploading = false;
        dispatch(isbulkUploading(false));
        callInitiateAPI();
        return;
      }
      let file = uploadfile[i - 1];
      if (type != "retry") {
        let uuid = uuidv4();
        uploadfile[i - 1].uuid = uuid;
        UploadedDuplicateMedia.push({
          name: file?.name,
          inspection_guid: sessionStorage.getItem("guid"),
          is_report_media_library_file: true,
          media_type: file?.type,
          online_url: null,
          thumbnail_url: null,
          times_used: 0,
          uuid: uuid,
          is_media_uploading: true,
        });
      } else {
        let mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
        if (mediaIndex !== -1) {
          UploadedDuplicateMedia[mediaIndex].is_media_uploading = true;
        }
      }
      let formData = new FormData();
      const fileSize = file.size;
      const sizeInMB = Math.round(fileSize / (1024 * 1024));
    }
    let reportData = storeData?.inspectionDataForBulkMedia;
    reportData.data.report_media_library_files = UploadedDuplicateMedia;
    dispatch(UpdateReduxLocal(reportData));
    for (let i = 1; i <= uploadfile.length; i++) {
      dispatch(isbulkUploading(true));
      if (cancelBulkUploading) {
        cancelBulkUploading = false;
        dispatch(isbulkUploading(false));
        callInitiateAPI();
        return;
      }
      let formData = new FormData();
      let file = uploadfile[i - 1];
      let fileType = null;
      if ((file?.type === "image/jpeg" && !file?.name.toLowerCase().endsWith(".jfif")) || file?.type === "image/png" || file?.type === "image/jpg") {
        fileType = "image";
      } else if (file?.type == "video/mov" || file?.type == "video/mp4" || file?.type == "video/quicktime") {
        fileType = "video";
      } else {
        fileType = "unsupported";
      }
      if (fileType === "image") {
        try {
          metadata = await exifr.parse(file);
        } catch (error) {
          metadata = await await GetUTCTime();
        }
      }
      if (fileType === "video") {
        metadata = file?.lastModifiedDate;
      }
      const fileSize = file.size;
      const sizeInMB = Math.round(fileSize / (1024 * 1024));
      if (sizeInMB >= 1024) {
        toast.error("File size too Big, please select a file less than 1024 MB");
        errorCount++;
        responseCount++;
        sizeErrorCount++;
        if (responseCount === uploadfile.length) {
          responseCount = 0;
          videoUploadCount = 0;
          imageUploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else if (!file.type.match(/(image\/(png|jpg|jpeg))|(video\/(mp4|mov|quicktime))/)) {
        const mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
        if (mediaIndex !== -1) {
          UploadedDuplicateMedia[mediaIndex].is_media_uploading = false;
        }
        let reportData = storeData?.inspectionDataForBulkMedia;
        reportData.data.report_media_library_files = UploadedDuplicateMedia;
        dispatch(UpdateReduxLocal(reportData));
        errorCount++;
        responseCount++;
        if (responseCount === uploadfile.length) {
          responseCount = 0;
          videoUploadCount = 0;
          imageUploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else {
        if (fileType === "image") {
          let compressedFile;
          let thumbImage;
          const { imgWidth, imgHeight } = await getImageDimensions(file);
          const maxWidth = 320;
          const maxHeight = 320;
          const quality = 0.8;
          let newWidth, newHeight;
          if (imgWidth > imgHeight) {
            // Landscape orientation
            newHeight = maxHeight;
            newWidth = (maxHeight / imgHeight) * imgWidth;
          } else {
            // Portrait orientation
            newWidth = maxWidth;
            newHeight = (maxWidth / imgWidth) * imgHeight;
          }
          let thumbnailImage = await resizeImage(file, newWidth, newHeight, quality);
          thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, { type: "image/jpeg" });
          if (file?.size > 512000) {
            // =======
            try {
              const compressedFilee = await compressImage(file);
              compressedFile = new File([compressedFilee], `${compressedFilee.name}`, { type: "image/jpeg" });
              let params = {};
              params = {
                fileToUpload: compressedFile,
                inspectionGuid: sessionStorage.getItem("guid"),
                created_at: moment.utc(metadata?.DateTimeOriginal).format("YYYY-MM-DD HH:mm:ss"),
                thumbnail: thumbImage,
                fileGuid: file?.uuid,
              };
              Object.keys(params).forEach((key) => formData.append(key, params[key]));
              // sync API call
              let baseUrl = await origin();
              let sessionAPIKey = sessionStorage.getItem("CK");
              let sessionSecretKey = sessionStorage.getItem("SK");
              try {
                const response = await axios.post(`${baseUrl}media-lib-file-upload`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
                    "api-key": `${Base64.decode(sessionAPIKey)}`,
                    platform: "web",
                  },
                });
                const result = response.data;
                const mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
                if (mediaIndex !== -1) {
                  UploadedDuplicateMedia[mediaIndex].online_url = result?.data?.onlineUrl;
                  UploadedDuplicateMedia[mediaIndex].thumbnail_url = result?.data?.thumbnail;
                  UploadedDuplicateMedia[mediaIndex].name = file?.name;
                }
                let reportData = storeData?.inspectionDataForBulkMedia;
                reportData.data.report_media_library_files = UploadedDuplicateMedia;
                dispatch(UpdateReduxLocal(reportData));
                responseCount++;
                imageUploadCount++;
                successCount++;
                dispatch(successfulBulkUploadMediaLength(successCount));
              } catch (error) {
                setFailedMediaList((prev) => [...prev, file]);
                failedBulkUpload.push(file);
                dispatch(failedBulkUploadMedia(failedBulkUpload));
                const mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
                if (mediaIndex !== -1) {
                  UploadedDuplicateMedia[mediaIndex].is_media_uploading = false;
                }
                let reportData = storeData?.inspectionDataForBulkMedia;
                reportData.data.report_media_library_files = UploadedDuplicateMedia;
                dispatch(UpdateReduxLocal(reportData));
                toast.error(error?.message);
                responseCount++;
                errorCount++;
                imageUploadCount++;
              }
              if (responseCount === uploadfile.length) {
                responseCount = 0;
                videoUploadCount = 0;
                imageUploadCount = 0;
                errorCount = 0;
                sizeErrorCount = 0;
                successCount = 0;
              }
            } catch (error) {
              console.error("Error compressing image:", error);
            }
          } else {
            compressedFile = file;
            let params = {};
            params = {
              fileToUpload: compressedFile,
              thumbnail: thumbImage,
              inspectionGuid: sessionStorage.getItem("guid"),
              created_at: moment.utc(metadata?.DateTimeOriginal).format("YYYY-MM-DD HH:mm:ss"),
              fileGuid: file?.uuid,
            };

            Object.keys(params).forEach((key) => formData.append(key, params[key]));
            // sync API call
            let baseUrl = await origin();
            let sessionAPIKey = sessionStorage.getItem("CK");
            let sessionSecretKey = sessionStorage.getItem("SK");
            try {
              const response = await axios.post(`${baseUrl}media-lib-file-upload`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
                  "api-key": `${Base64.decode(sessionAPIKey)}`,
                  platform: "web",
                },
              });
              const result = response.data;
              const mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
              if (mediaIndex !== -1) {
                UploadedDuplicateMedia[mediaIndex].online_url = result?.data?.onlineUrl;
                UploadedDuplicateMedia[mediaIndex].thumbnail_url = result?.data?.thumbnail;
                UploadedDuplicateMedia[mediaIndex].name = file?.name;
              }
              let reportData = storeData?.inspectionDataForBulkMedia;
              reportData.data.report_media_library_files = UploadedDuplicateMedia;
              dispatch(UpdateReduxLocal(reportData));
              responseCount++;
              imageUploadCount++;
              successCount++;
              dispatch(successfulBulkUploadMediaLength(successCount));
            } catch (error) {
              setFailedMediaList((prev) => [...prev, file]);
              failedBulkUpload.push(file);
              dispatch(failedBulkUploadMedia(failedBulkUpload));
              const mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
              if (mediaIndex !== -1) {
                UploadedDuplicateMedia[mediaIndex].is_media_uploading = false;
              }
              let reportData = storeData?.inspectionDataForBulkMedia;
              reportData.data.report_media_library_files = UploadedDuplicateMedia;
              dispatch(UpdateReduxLocal(reportData));
              responseCount++;
              errorCount++;
              imageUploadCount++;
              toast.error(error?.message);
            }
            if (responseCount === uploadfile.length) {
              responseCount = 0;
              videoUploadCount = 0;
              imageUploadCount = 0;
              errorCount = 0;
              sizeErrorCount = 0;
              successCount = 0;
            }
          }
        } else if (fileType === "video") {
          await generateVideoThumbnails(file, 10).then(async (thumbs) => {
            await convertBase64ToFile(thumbs, "name", file, formData, fileType, currentMediaLength, i, e, type, UploadedDuplicateMedia, failedBulkUpload, metadata);
          });
        } else {
          errorCount++;
          const mediaIndex = UploadedDuplicateMedia?.findIndex((item) => item?.uuid === file?.uuid);
          if (mediaIndex !== -1) {
            UploadedDuplicateMedia[mediaIndex].is_media_uploading = false;
          }
          let reportData = storeData?.inspectionDataForBulkMedia;
          reportData.data.report_media_library_files = UploadedDuplicateMedia;
          dispatch(UpdateReduxLocal(reportData));
          dispatch(isbulkUploading(false));
          dispatch(failBulkUploadMediaLength(errorCount));
        }
      }
    }
    dispatch(isbulkUploading(false));
  };

  const handleRetryFailMedia = () => {
    handleAddMedia(storeData?.failedBulkUploadMedia, "retry");
  };

  const callInitiateAPI = async () => {
    let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem("AC"));
    let guid = sessionStorage.getItem("guid");
    let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
    dispatch(getInspectionDataWithOutLoader({ guid: guid, associate_company: sessionAssociateCompanyKey, user_id: sessionUserId }));
    dispatch(isbulkUploading(false));
    dispatch(successfulBulkUploadMediaLength(0));
    dispatch(failBulkUploadMediaLength(0));
    dispatch(bulkUploadMediaLength(0));
    dispatch(failedBulkUploadMedia([]));
  };

  useEffect(() => {
    {
      isReportMediaLibrary &&
        setTimeout(() => {
          if (storeData?.bulkUploadMediaLength > 0 && storeData?.successfulBulkUploadMediaLength > 0 && storeData?.bulkUploadMediaLength == storeData?.successfulBulkUploadMediaLength) {
            handleCancelBulkUploadErrorModal();
          }
        }, 5000);
    }
  }, [storeData?.isbulkUploading]);

  function mergeObjectsByCategoryId(data) {
    const mergedObjects = {};
    data?.forEach((item) => {
      if (item) {
        [item].forEach((innerItem) => {
          const categoryId = innerItem.category_id;
          if (!mergedObjects[categoryId]) {
            mergedObjects[categoryId] = { ...innerItem };
          } else {
            mergedObjects[categoryId].value = mergedObjects[categoryId]?.value?.concat(innerItem.value);
          }
        });
      }
    });
    return Object.values(mergedObjects);
  }
  const templateMediaString = "template-editor/";

  useEffect(() => {
    let media = [];
    storeData?.inspectionDataForBulkMedia?.data?.report_media_library_files?.forEach((item) => {
      media.push({ ...item, onlineUrl: item?.online_url, thumbnail: item?.thumbnail_url, type: item?.media_type, edited: false });
    });
    storeData?.inspectionDataForBulkMedia?.data?.inspectiontemplates?.forEach((template) => {
      if (template.is_pdf_template !== 1) {
        template?.categories?.forEach((category) => {
          category?.introductory_text_media?.forEach((image) => {
            if (image?.onlineUrl?.startsWith(templateMediaString) || image?.thumbnail?.startsWith(templateMediaString)) {
              return;
            }
            let times_used = 1;
            if (category?.is_deleted === 1 || image?.is_deleted === 1) {
              times_used = 0;
            }
            if (image?.is_report_media_library_file) {
              media?.push({ ...image, onlineUrl: image?.online_url, thumbnail: image?.thumbnail_url, type: image?.media_type, times_used: times_used });
            } else {
              media?.push({ ...image, online_url: image?.onlineUrl, thumbnail_url: image?.thumbnail, media_type: image?.type, times_used: times_used, template_media: true });
            }
          });
          category?.lines?.forEach((line) => {
            line?.line_input_controls !== null &&
              line?.line_input_controls !== undefined &&
              line?.line_input_controls.length !== 0 &&
              line?.line_input_controls
                ?.filter((data) => data.type === "Media")
                ?.forEach((MediaData) => {
                  MediaData?.data?.forEach((image) => {
                    if (image?.onlineUrl?.startsWith(templateMediaString) || image?.thumbnail?.startsWith(templateMediaString)) {
                      return;
                    }
                    let times_used = 1;
                    if (category?.is_deleted === 1 || line?.is_deleted === 1 || image?.is_deleted === 1) {
                      times_used = 0;
                    }
                    if (image?.is_report_media_library_file) {
                      media.push({ ...image, onlineUrl: image?.online_url, thumbnail: image?.thumbnail_url, type: image?.media_type, times_used: times_used });
                    } else {
                      media?.push({ ...image, online_url: image?.onlineUrl, thumbnail_url: image?.thumbnail, media_type: image?.type, times_used: times_used, template_media: true });
                    }
                  });
                });
            line?.line_input_controls?.filter((data) => data?.type == "Nameplate")?.[0]?.data?.image &&
              media.push(line?.line_input_controls?.filter((data) => data?.type == "Nameplate")?.[0]?.data?.image);
            line.comments.length !== 0 &&
              line?.comments?.forEach((comment) =>
                comment?.comment_input_controls
                  ?.filter((data) => data.type === "Media")
                  ?.forEach((innerData) => {
                    innerData?.data?.forEach((image) => {
                      if (image?.onlineUrl?.startsWith(templateMediaString) || image?.thumbnail?.startsWith(templateMediaString)) {
                        return;
                      }
                      let times_used = 1;
                      if (image?.is_deleted === 1) {
                        times_used = 0;
                      }
                      if (image?.is_report_media_library_file) {
                        media.push({ ...image, onlineUrl: image?.online_url, thumbnail: image?.thumbnail_url, type: image?.media_type, times_used: times_used });
                      } else {
                        media?.push({ ...image, online_url: image?.onlineUrl, thumbnail_url: image?.thumbnail, media_type: image?.type, times_used: times_used, template_media: true });
                      }
                    });
                  })
              );
          });
        });
      } else {
        {
          mergeObjectsByCategoryId(
            template?.pdf_data?.filter((category) => {
              return category?.type === "MediaUploader" && category;
            })
          )?.forEach((item) => {
            if (item.value.length !== 0) {
              item.value?.forEach((image) => {
                if (image?.onlineUrl?.startsWith(templateMediaString) || image?.thumbnail?.startsWith(templateMediaString)) {
                  return;
                }
                let times_used = 1;
                if (image?.is_deleted === 1) {
                  times_used = 0;
                }
                if (image?.is_report_media_library_file) {
                  media.push({ ...image, onlineUrl: image?.online_url, thumbnail: image?.thumbnail_url, type: image?.media_type, times_used: times_used });
                } else {
                  media?.push({ ...image, online_url: image?.onlineUrl, thumbnail_url: image?.thumbnail, media_type: image?.type, times_used: times_used, template_media: true });
                }
              });
            } else return null;
          });
        }
      }
    });
    let uniqueMedia = [];
    let mediaWithOriginal = [];
    media.forEach((mediaItem) => {
      const mediaDeletedFromRML = mediaItem?.is_deleted_from_rml || 0;
      const mediaIndex = mediaWithOriginal.findIndex((itm) => {
        return mediaItem.uuid == itm.uuid && mediaItem.name == itm.name;
      });
      const mediaIndexByName = mediaWithOriginal.findIndex((itm) => {
        return mediaItem.name == itm.name;
      });
      if (mediaIndex !== -1) {
        mediaWithOriginal[mediaIndex]["isUserMade"] = true;
        const newItemIsDeleted = mediaItem?.is_deleted || 0;
        const mediaIndexLocal = mediaWithOriginal.findIndex((itm) => {
          return mediaItem.uuid == itm.uuid && mediaItem.name == itm.name;
        });
        if (newItemIsDeleted === 0 && mediaItem?.template_media && mediaIndexLocal !== -1) {
          if (mediaItem?.edited == false) {
            mediaWithOriginal[mediaIndexLocal].times_used = (mediaWithOriginal[mediaIndexLocal].times_used || 0) + 1;
          }
          mediaWithOriginal[mediaIndexLocal]["isUserMade"] = true;
          if (mediaWithOriginal[mediaIndexLocal].updated_at < mediaItem?.updated_at) {
            mediaWithOriginal[mediaIndexLocal].updated_at = mediaItem?.updated_at;
          }
        }
        // add only edited
        if (mediaItem?.edited && mediaDeletedFromRML !== 2 && mediaDeletedFromRML !== 3) {
          if (mediaIndexByName == -1) {
            mediaWithOriginal.push({
              ...mediaItem,
              id: uuidv4(),
              created_at: mediaItem?.updated_at,
              times_used: mediaItem?.is_deleted ? 0 : 1,
            });
          }
        }
      } else {
        const mediaDeletedFromRML = mediaItem?.is_deleted_from_rml || 0;
        if (mediaItem?.edited) {
          //store original as well
          if (mediaDeletedFromRML === 0 || mediaDeletedFromRML === 2) {
            let originalImage = {
              ...mediaItem,
              thumbnail: mediaItem.OriginThumbnail,
              newUrl: mediaItem?.OriginUrl,
              onlineUrl: mediaItem?.OriginUrl,
              id: uuidv4(),
              name: mediaItem?.OrginName || mediaItem?.orginName,
              edited: false,
              editCount: 0,
              times_used: 0,
              isUserMade: true,
            };
            const mediaIndexByNameLocal = mediaWithOriginal.findIndex((itm) => {
              return originalImage.name == itm.name;
            });
            if (mediaIndexByNameLocal == -1) {
              mediaWithOriginal.push(originalImage);
            }
          }
          //push edited if 0 or 1
          if (mediaDeletedFromRML === 0 || mediaDeletedFromRML === 1) {
            const editedItem = { ...mediaItem, created_at: mediaItem?.updated_at, times_used: 1 };
            if (mediaIndexByName == -1) {
              mediaWithOriginal.push(editedItem);
            }
          }
        } else {
          if (mediaDeletedFromRML !== 1 && mediaDeletedFromRML !== 3) {
            if (mediaIndexByName == -1) {
              mediaWithOriginal.push(mediaItem);
            }
          }
        }
      }
    });

    uniqueMedia = mediaWithOriginal;
    let filteredMedia = uniqueMedia;
    if (filterValue == "Unused") {
      filteredMedia = uniqueMedia?.filter((data) => data?.times_used == 0);
    } else if (filterValue == "Used") {
      filteredMedia = uniqueMedia?.filter((data) => data?.times_used > 0 || data?.times_used == undefined);
    } else {
      filteredMedia = uniqueMedia;
    }
    const sortedMedia = filterValueFunction(filteredMedia, selected);
    setMediaList(sortedMedia);
  }, [storeData, filterValue, selected]);

  const parseDate = (dateString) => {
    if (!dateString?.includes("T")) {
      dateString = dateString?.replace(" ", "T") + ".000Z";
    }
    return new Date(dateString);
  };

  const filterValueFunction = (result, filter) => {
    if (filter === "Newest") {
      return result?.sort((a, b) => parseDate(b.created_at) - parseDate(a.created_at));
    } else if (filter === "Oldest") {
      return result?.sort((a, b) => parseDate(a.created_at) - parseDate(b.created_at));
    } else if (filter === "Last used") {
      return result?.sort((a, b) => parseDate(b.updated_at) - parseDate(a.updated_at));
    } else if (filter === "File name") {
      return result?.sort((a, b) => a.name.localeCompare(b.name));
    } else if (filter === "Most used") {
      return result?.filter((data) => data?.is_deleted !== 1)?.sort((a, b) => b?.times_used - a?.times_used);
    } else {
      return result;
    }
  };

  useEffect(() => {
    if (!storeData?.isbulkUploading) callInitiateAPI();
  }, []);

  const handleClose = async () => {
    setOpenDeleteMediaModal(false);
  };

  const handleDeleteRMLMedia = async () => {
    const updateTime = await GetUTCTime();
    const result = await DeleteRMLMedia(storeData, deleteMedia);
    let templateId = null;
    let categoryId = null;
    const SelectIntroductoryTextMedia = storeData?.inspectionData?.data?.inspectiontemplates?.flatMap((template) =>
      template?.categories?.flatMap((category) =>
        category?.introductory_text_media?.filter((media) => {
          if (media?.uuid === deleteMedia?.uuid) {
            media.is_deleted = 1;
            media.is_deleted_from_rml = media?.is_deleted_from_rml == 1 || media?.is_deleted_from_rml == 2 ? 3 : media?.edited ? 2 : 1;
            media.updated_at = updateTime;
            templateId = template?.template_id;
            categoryId = category?.id;
            return true;
          }
          return false;
        })
      )
    );
    if (SelectIntroductoryTextMedia?.length != 0) {
      const formData = {
        template_id: templateId,
        category_id: categoryId,
        inspection_id: storeData?.inspectionData?.data?.inspection_id,
        guid: storeData?.inspectionData?.data?.guid,
        introductory_text_media: SelectIntroductoryTextMedia,
      };
      await apiCallintroductoryTextMedia(dispatch, formData);
    }
    await UpdateTemplateData(dispatch, result);
    setTimeout(() => {
      callInitiateAPI();
    }, 3000);
    setOpenDeleteMediaModal(false);
  };

  return (
    <motion.div className="h_100 w_bg" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Header title="Report Media Library" />
      <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", borderBottom: 1, borderColor: "divider" }}>
        <Box className="report_library__tab" sx={{ display: "flex", flexWrap: "wrap" }}>
          <Tabs className="library_tab" value={activeTab} onChange={handleChange} aria-label="report_library_tab">
            <Tab className="tab__label" label="All" {...a11yProps(0)} />
            <Tab className="tab__label" label={"Images"} {...a11yProps(1)} sx={{ maxWidth: "61px" }} />
            <Tab className="tab__label" label={"Videos"} {...a11yProps(1)} sx={{ maxWidth: "58px" }} />
          </Tabs>
          <div className="filter_container" style={{ height: "36px" }}>
            <ThemeProvider theme={theme} className="maxwithclsss">
              <Select
                value={filterValue}
                onChange={handleFilterChange}
                className="rl-textfield"
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      px: "4px",
                      borderRadius: "4px",
                      border: "1px solid #E6E6E6",
                      minWidth: "150px",
                    },
                  },
                  PaperProps: { elevation: 0, sx: { mt: 1, gap: "8px" } },
                }}
                size="small"
                renderValue={(value) => (
                  <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "inherit" }}>
                    <Typography noWrap sx={{ color: "#737373 !important" }} className="filter_value">
                      {value}
                    </Typography>
                  </div>
                )}
                startAdornment={<FilterListIcon className="rl-filter-icon" fontSize="small" sx={{ width: "13px", height: "13px", mr: "2px", color: "#737373 !important" }} />}
              >
                {sortFilters.map((option) => (
                  <MenuItem
                    value={option.value}
                    selected={filterValue === option.value}
                    sx={{
                      marginBottom: "5px",
                      borderRadius: "8px",
                      height: "32px",
                      "&.Mui-selected": {
                        backgroundColor: "#C7DBF6",
                        color: "white",
                        border: "1px solid #4282E2",
                        justifyContent: "space-between",
                      },
                      "&:hover": {
                        backgroundColor: "#e1ecfa",
                      },
                    }}
                  >
                    <Typography className={filterValue === option.value ? "report-filter-text-selected" : "report-filter-text-option"}>{option?.label}</Typography>
                    {filterValue === option.value && <CheckIcon sx={{ color: "#2F1073", height: "16px", width: "16px" }} />}
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </div>
          <div className="filter_container" style={{ height: "36px" }}>
            <ThemeProvider theme={theme}>
              <Select
                value={selected}
                onChange={handleTimeFilterChange}
                className="rl-textfield"
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      px: "4px",
                      borderRadius: "4px",
                      border: "1px solid #E6E6E6",
                      minWidth: "150px",
                    },
                  },
                  PaperProps: { elevation: 0, sx: { mt: 1, gap: "8px" } },
                }}
                size="small"
                renderValue={(value) => (
                  <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "inherit" }}>
                    <Typography noWrap sx={{ color: "#737373 !important" }} className="filter_value">
                      {value}
                    </Typography>
                  </div>
                )}
                startAdornment={<SwapVertIcon className="rl-filter-icon" fontSize="small" sx={{ width: "13px", height: "13px", mr: "2px", color: "#737373 !important" }} />}
              >
                {filters.map((option) => (
                  <MenuItem
                    value={option.value}
                    selected={selected === option.value}
                    sx={{
                      marginBottom: "5px",
                      borderRadius: "8px",
                      height: "32px",
                      "&.Mui-selected": {
                        backgroundColor: "#C7DBF6",
                        color: "white",
                        border: "1px solid #4282E2",
                        justifyContent: "space-between",
                      },
                      "&:hover": {
                        backgroundColor: "#e1ecfa",
                      },
                    }}
                  >
                    <Typography className={selected === option.value ? "report-filter-text-selected" : "report-filter-text-option"}>{option?.label}</Typography>
                    {selected === option.value && <CheckIcon sx={{ color: "#2F1073", height: "16px", width: "16px" }} />}
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </div>
        </Box>
        <Box className="add_media_tab">
          {!storeData?.isbulkUploading ? (
            <Button
              className="report-media-library-add-media-button"
              onClick={() => {
                if (fileInput.current) {
                  fileInput.current.click();
                }
              }}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              startIcon={<AddIcon />}
            >
              Add media
              <input
                ref={fileInput}
                type="file"
                accept="image/jpg,image/png,image/jpeg,video/mov,video/mp4,video/quicktime"
                onChange={handleAddMedia}
                style={{ display: "none" }}
                multiple
                component="label"
              />
            </Button>
          ) : (
            <Button
              className="disabled-report-media-library-add-media-button"
              variant="contained"
              color="primary"
              sx={{ textTransform: "none", backgroundColor: "#e5e5e5" }}
              startIcon={<AddIcon />}
              disabled
            >
              Add media
            </Button>
          )}
        </Box>
      </Box>
      <TabPanel value={activeTab} index={0} className="tab_panel">
        <ReportLibraryAllMedia
          storeData={storeData}
          handleCancelBulkUploadMedia={handleCancelBulkUploadMedia}
          mediaList={mediaList}
          handleErrorDoneButton={handleErrorDoneButton}
          handleRetryFailMedia={handleRetryFailMedia}
          failedMediaList={failedMediaList}
          setHoveredDeletedMediaUuid={setHoveredDeletedMediaUuid}
          hoveredDeletedMediaUuid={hoveredDeletedMediaUuid}
          openDeleteMediaModal={openDeleteMediaModal}
          setOpenDeleteMediaModal={setOpenDeleteMediaModal}
          handleClose={handleClose}
          setDeleteMedia={setDeleteMedia}
          deleteMedia={deleteMedia}
          handleDeleteRMLMedia={handleDeleteRMLMedia}
          view="rml"
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1} className="tab_panel">
        <ReportLibraryImage
          storeData={storeData}
          mediaList={mediaList}
          handleErrorDoneButton={handleErrorDoneButton}
          handleCancelBulkUploadMedia={handleCancelBulkUploadMedia}
          handleRetryFailMedia={handleRetryFailMedia}
          failedMediaList={failedMediaList}
          setHoveredDeletedMediaUuid={setHoveredDeletedMediaUuid}
          hoveredDeletedMediaUuid={hoveredDeletedMediaUuid}
          openDeleteMediaModal={openDeleteMediaModal}
          setOpenDeleteMediaModal={setOpenDeleteMediaModal}
          handleClose={handleClose}
          setDeleteMedia={setDeleteMedia}
          deleteMedia={deleteMedia}
          handleDeleteRMLMedia={handleDeleteRMLMedia}
          view="rml"
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2} className="tab_panel">
        <ReportLibraryVideo
          storeData={storeData}
          mediaList={mediaList}
          handleErrorDoneButton={handleErrorDoneButton}
          handleCancelBulkUploadMedia={handleCancelBulkUploadMedia}
          handleRetryFailMedia={handleRetryFailMedia}
          failedMediaList={failedMediaList}
          setHoveredDeletedMediaUuid={setHoveredDeletedMediaUuid}
          hoveredDeletedMediaUuid={hoveredDeletedMediaUuid}
          openDeleteMediaModal={openDeleteMediaModal}
          setOpenDeleteMediaModal={setOpenDeleteMediaModal}
          handleClose={handleClose}
          setDeleteMedia={setDeleteMedia}
          deleteMedia={deleteMedia}
          handleDeleteRMLMedia={handleDeleteRMLMedia}
          view="rml"
        />
      </TabPanel>
    </motion.div>
  );
}

export default ReportLibrary;
